import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ValidateAllFormFields} from '../../../../../../functions/validateAllFormFields';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {environment} from '../../../../../../../environments/environment';
import {Company} from '../../../../../../models/company';
import moment from 'moment/moment';
import {MessageCenter, MessageCenterAction, MessageCenterActionMessage} from '../../../../../../models/message-center';
import {MessageAutomationRuleService} from '../../../../../../services/message-automation-rule.service';
import {MessageRuleActionsUpsertComponent} from './parts/message-rule-actions-upsert/message-rule-actions-upsert.component';
import {LanguageService} from 'app/services/language.service';
import {CanDeactivateGuard} from '../../../../../../guards/can-deactivate-guard';
import {Observable} from 'rxjs/Observable';
import {MessageRuleAddWizardComponent} from './parts/message-rule-add-wizard/message-rule-add-wizard.component';

@Component({
  selector: 'app-message-center-rule-upsert',
  templateUrl: './message-center-rule-upsert.component.html',
  styleUrls: ['./message-center-rule-upsert.component.scss']
})
export class MessageCenterRuleUpsertComponent extends ValidateAllFormFields implements OnInit, OnDestroy, CanDeactivateGuard {
  @ViewChild('messageRuleActionsUpsertComponent') messageRuleActionsUpsertComponent!: MessageRuleActionsUpsertComponent;

  recipientTypes = [
    'passenger',
    'driver',
    'debtor',
  ];

  matchValues = [
    'job_created',
    'job_changed_assigned',
    'job_changed_driving_to_pickup',
    'job_changed_arrived_at_pickup',
    'job_changed_started',
    'job_changed_completed',
    'job_changed_canceled',
    'driver_changed',
    'requesteddate_changed',
  ];

  matchValues2 = [
    'add_sms_action',
    'add_email_action'
  ];

  wizardCompleted = false;
  form: UntypedFormGroup;
  newForm: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  validationMessages = {
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'title': {
      'fieldName': 'title',
      'required': 'form_validation_required',
    },
    'text': {
      'fieldName': 'text',
      'required': 'form_validation_required',
    },
    'startDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
    },
    'endDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
      'error_start_and_end_date_same': 'error_start_and_end_date_same'
    },
  };
  loaderName = 'messagecenterrule';
  modelName = 'JobMessageAutomationRule';
  list = 'message-center';
  currentAction: string;
  id: string;
  data: any = new MessageCenter();
  companyId: string;
  currency: string;
  action: string;
  languages: string[];
  company: Company;

  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');
  maxStartDate = moment().add('1', 'month').format('YYYY-MM-DDTHH:mm');

  constructor( private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _snackbar: MatSnackBar,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _languageService: LanguageService,
              private _messageAutomationRuleService: MessageAutomationRuleService) {
    super();
    this.loadErrorTranslations(_translateService);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.currency = company.currency;
    this.company = company;
    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];

        _translateService.get([
          `${this.action}_message_rule`,
          'message_center_rule',
          'promotion_published',
          'message_center_rule_added_message',
          'message_center_rule_added_title',
          'messagerule_updated_message',
          'message_center_rule_not_added',
          'message_center_rule_not_saved',
          'message_center_rule_not_saved_missing_requirements',
          'message_center_rule_not_saved_missing_actions',
          'delete_x',
          'message_delete_x',
          'one_action_not_valid',
          'not_saved',
          'x_deleted'
        ]).subscribe((translations: any) => {
          self.translations = translations;
          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': self.translations['message_center_rule'],
              'link': `/groups/${self.companyId}/${self.list}/`
            }
          ]);

          this.translations = translations;
          this._titleService.setTitle(ucFirst(translations[`${self.action}_message_rule`]) + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(ucFirst(translations[`${self.action}_message_rule`]));
        });

        self._languageService.getAll({'order': 'name ASC', where:{'active': true}}).subscribe((languages) => {
          self.languages = languages;
          if (this.action === 'edit') {
            this.loadData();
          } else {
            this.initForm();
            // this._loadingService.resolve(this.loaderName);

          this._dialogService.open(MessageRuleAddWizardComponent, {
            maxHeight: '90%',
            height: 'auto',
            disableClose: true,
            maxWidth: (window.innerWidth < 600 ? '100%' : '800px'),
            data: {
              company: this.company,
              parent: this,
            }
          });
          }
        });
      });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }

  initForm() {
    this.form = this._formBuilder.group({
      name: [this.data.name, [Validators.required]],
      recipientType: [(this.data.recipientType ? this.data.recipientType : 'passenger'), [Validators.required]],
      dateLimit: [(!this.data.dateLimit && this.action !== 'add' ? false : true), []],
      requirements: this._formBuilder.array([]),
      actions: this._formBuilder.array([]),
      startDate: [(this.data.startDate ? moment(this.data.startDate).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')), []],
      endDate: [(this.data.endDate ? moment(this.data.endDate).format('YYYY-MM-DDTHH:mm') : this.maxStartDate), []],
    }, {validators: []});

    this.newForm = this._formBuilder.group({
      recipientType: ['passenger', []],
      newFlowCondition: ['job_created', []],
      newFlowAction: ['add_email_action', []],
    }, {validators: []});

    // if (this.messageRuleConditionsUpsertComponent) {
    //   this.messageRuleConditionsUpsertComponent.loadData();
    //   this.messageRuleActionsUpsertComponent.loadData();
    // }
    this.conditionalFormatting();
  }

  conditionalFormatting() {
    this.form.get('endDate').setValidators([Validators.required, this.dateComparison(this.form)]);

    this.form.get('startDate').valueChanges.subscribe(
      (mode: string) => {
        this.minDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });

    this.form.get('endDate').valueChanges.subscribe(
      (mode: string) => {
        this.maxStartDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });
  }

  loadData(): void {
    // console.log(`[promotionUpsertComponent.loadData]: `);
    this._messageAutomationRuleService.get(this.id, {}).subscribe((rule: any) => {
      this.data = rule;
      this.initForm();
      this.mapDataToRequirements();
      this.mapActions();
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  save(): void {
    if (this.action || true) {
      const self = this;
      this.form.controls['requirements'].updateValueAndValidity();
      this.form.controls['actions'].updateValueAndValidity();
      const data = this.form.value;
      const formattedActions = [];
      let smsIndex = 0;
      data.actions.forEach((a: MessageCenterAction) => {
        a.messages.forEach((m: MessageCenterActionMessage) => {
          m.message = m.message.replace(new RegExp('{passenger.', 'g'), '{passenger_');
          m.message = m.message.replace(new RegExp('{driver.', 'g'), '{driver_');
          m.message = m.message.replace(new RegExp('{company.', 'g'), '{company_');
          m.message = m.message.replace(new RegExp('{vehicle.', 'g'), '{vehicle_');
          m.message = m.message.replace(new RegExp('{ride.', 'g'), '{ride_');
        });
      });
      const rule: any = {
        name: data.name,
        recipientType: data.recipientType,
        startDate: moment(data.startDate).toISOString(),
        endDate: moment(data.endDate).toISOString(),
        dateLimit: data.dateLimit,
        _requirements: this.mappedRequirements(data.requirements),
        _actions: data.actions,
        status: (data.status ? data.status : 'published'),
      };

      this.validateAllFormFields(this.form);
      if (this.form.valid && rule._requirements.length > 0 && rule._actions.length > 0) {
        /**
         * Call the API
         */
        if (this.action.toLowerCase() === 'add') {
          rule.companyId = this.company.id;
          this._loadingService.register(this.loaderName);
          this._messageAutomationRuleService.insert(rule).subscribe((result) => {
            const formData = new FormData();
            this.action = 'leave';
            this._loadingService.resolve(this.loaderName);
            self._dialogService.openAlert({
              message: ucFirst(self.translations['message_center_rule_added_message']),
              disableClose: true,
              title: ucFirst(self.translations['message_center_rule_added_title']),
              closeButton: self.translations['ok']
            }).afterClosed().subscribe(() => {
              self.currentAction = null;
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/message-center`]);
            });
          }, (error) => {
            this._snackbar.open(this.translations['message_center_rule_not_saved'], this.translations['ok'], {
              duration: 3000
            });
            this._loadingService.resolve(this.loaderName);
          });
        } else {
          self._loadingService.register(this.loaderName);
          self._messageAutomationRuleService.update(this.id, rule).subscribe(() => {
            self.loadData();
            setTimeout(function () {
              self._loadingService.resolve(self.loaderName);
              self._snackbar.open(ucFirst(self.translations['messagerule_updated_message']), 'OK', {
                duration: 3000,
                panelClass: 'snack-success'
              });
            }, 500);
          }, error => {
            console.error(error);
            this._snackbar.open(this.translations['message_center_rule_not_saved'], this.translations['ok'], {
              duration: 3000,
              panelClass: 'snack-error'
            });
            this._loadingService.resolve(this.loaderName);
          });
        }
      } else {
        if (rule._requirements.length < 1) {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['not_saved']),
            disableClose: true,
            title: ucFirst(self.translations['message_center_rule_not_saved_missing_requirements']),
            closeButton: self.translations['ok'],
          });
        } else if (rule._actions.length < 1) {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['not_saved']),
            disableClose: true,
            title: ucFirst(self.translations['message_center_rule_not_saved_missing_actions']),
            closeButton: self.translations['ok'],
          });
        } else {
          let message = self.translations['not_saved'];
          if (!this.form.valid) {
            console.log('Form valid: ', this.form.valid);
            console.log('actions:', this.form.controls.actions.status);
            console.log('requirements:', this.form.controls.requirements.status);
            console.log(this.form.controls.requirements.errors);

            if (this.form.controls.actions.status === 'INVALID') {
              message = self.translations['one_action_not_valid'];
              console.log(this.form.controls.actions.value);
            }

            if (this.form.controls.requirements.status === 'INVALID') {
              message = self._translateService.instant('one_requirement_not_valid');
              console.log(this.form.controls.requirements.value);
              console.log(this.form.controls.requirements);
            }
          }
          self._dialogService.openAlert({
            message: ucFirst(message),
            disableClose: true,
            title: ucFirst(self.translations['message_center_rule_not_saved']),
            closeButton: self.translations['ok'],
          });
        }
      }
    }
  }

  cancel(): void {
    const self = this;
    self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/message-center`]);
  }

  mappedRequirements (requirements): any {
    const reqs = [];
    let status = 'unassigned';
    let matchKey = 'jobStatus';
    let secondKey;
    let secondValue;
    let timeKey;
    let timeMatchType;

    requirements.forEach((rule) => {
      switch (rule.firstMatch) {
        case 'job_created':
          status = 'unassigned';
          break;
        case 'job_changed_assigned':
          status = 'assigned';
          break;
        case 'job_changed_driving_to_pickup':
          status = 'driving_to_pickup';
          break;
        case 'job_changed_arrived_at_pickup':
          status = 'arrived_at_pickup';
          break;
        case 'job_changed_started':
          status = 'started';
          break;
        case 'job_changed_completed':
          status = 'completed';
          break;
        case 'job_changed_canceled':
          status = 'canceled';
          break;
        case 'driver_changed':
          status = 'job_details_changed_driver';
          matchKey = 'driver_changed';
          break;
        case 'requesteddate_changed':
          status = 'job_details_changed_requested';
          matchKey = 'requesteddate_changed';
          break;
      }
      reqs.push({
        'matchKey' : matchKey,
        'matchType' : 'fullmatch',
        'value' : status
      });

      switch (rule.secondMatch) {
        case 'requestedDate':
          reqs.push({
            'matchKey': rule.secondMatch,
            'matchType': 'date',
            'startDate': rule.startDate,
            'endDate': rule.endDate,
            'value': 'between'
          });
          break;
        default:
          secondKey = rule.secondMatch;
          secondValue = rule.secondMatchValue;
          break;
      }
      if (secondKey) {
        reqs.push({
          'matchKey': secondKey,
          'matchType': 'partial',
          'value': secondValue
        });
      }


      switch (rule.timeMatch) {
        case 'wait_for':
          timeKey = 'wait_for';
          timeMatchType = 'after';
          break;
        case 'is_before_requested_date':
          timeKey = 'is_before_requested_date';
          timeMatchType = 'before';
          break;
        case 'is_before_etp':
          timeKey = 'is_before_etp';
          timeMatchType = 'before';
          break;
      }
      if (timeKey) {
        reqs.push({
          'matchKey': timeKey,
          'matchType': timeMatchType,
          'value': rule.timeValue
        });
      }
    });
    return reqs;
  }

  mapDataToRequirements (): any {
    const control = {
      firstMatch: ['job_created', [Validators.required]],
      secondMatch: ['', []],
      secondMatchValue: ['', []],
      startDate: ['', []],
      endDate: ['', []],
      timeMatch: ['sent_immediately', [Validators.required]],
      timeValue: ['', []],
    };
    this.data._requirements.forEach((rule) => {
      if (rule.matchKey === 'jobStatus') {
        switch (rule.value) {
          case 'unassigned':
            control.firstMatch =  ['job_created', [Validators.required]];
            break;
          case 'assigned':
            control.firstMatch =  ['job_changed_assigned', [Validators.required]];
            break;
          case 'driving_to_pickup':
            control.firstMatch =  ['job_changed_driving_to_pickup', [Validators.required]];
            break;
          case 'arrived_at_pickup':
            control.firstMatch =  ['job_changed_arrived_at_pickup', [Validators.required]];
            break;
          case 'started':
            control.firstMatch =  ['job_changed_started', [Validators.required]];
            break;
          case 'completed':
            control.firstMatch =  ['job_changed_completed', [Validators.required]];
            break;
          case 'canceled':
            control.firstMatch =  ['job_changed_canceled', [Validators.required]];
            break;
        }
      } else if (['driver_changed', 'requesteddate_changed'].includes(rule.matchKey)) {
        control.firstMatch =  [rule.matchKey, [Validators.required]];
        // console.log(control);
      } else if (['departure', 'destination', 'requestedDate', 'paymentMethod', 'debtorCode', 'note'].includes(rule.matchKey)) {
          control.secondMatch =  [rule.matchKey, []],
          control.secondMatchValue =  [rule.value, []];
      } else {
        switch (rule.matchKey) {
          case 'sent_immediately':
            control.timeMatch =  ['sent_immediately', []];
            break;
          case 'wait_for':
            control.timeMatch =  [rule.matchKey, []];
            control.timeValue =  [rule.value, []];
            break;
          case 'is_before_requested_date':
            control.timeMatch =  ['is_before_requested_date', []];
            control.timeValue =  [rule.value, []];
            break;
          case 'is_before_etp':
            control.timeMatch =  [rule.matchKey, []];
            control.timeValue =  [rule.value, []];
            break;
        }
      }
    });
    // @ts-ignore
    this.form.controls['requirements'].controls.push(this._formBuilder.group(control, {validators: []}));
    this.form.controls['requirements'].updateValueAndValidity();
  }

  mapActions(): any {
    this.data._actions = this.data._actions.map((actionList) => {
      actionList.messages = actionList.messages.map((a) => {
        a.message = a.message.replace(new RegExp('{passenger_', 'g'), '{passenger.');
        a.message = a.message.replace(new RegExp('{driver_', 'g'), '{driver.');
        a.message = a.message.replace(new RegExp('{vehicle_', 'g'), '{vehicle.');
        a.message = a.message.replace(new RegExp('{company_', 'g'), '{company.');
        a.message = a.message.replace(new RegExp('{ride_', 'g'), '{ride.');
        return a;
      });
      return actionList;
    });
  }

  autoStartAddMessage() {
    const self = this;
    if (self.messageRuleActionsUpsertComponent) {
      self.messageRuleActionsUpsertComponent.addActionRow(self.newForm.controls.newFlowAction.value);
    } else {
      setTimeout(function() {
        self.autoStartAddMessage();
      }, 200);
    }
  }

  /**
   * Guard checks whether component can be deactivated so that
   * the user doesn't accidentally navigate away from a filled
   * in form.
   */
  canDeactivate(): Observable<boolean> | boolean {
    let touched = false;
    if (this.action === 'leave') {
      return true;
    }
    this.form.controls['requirements']['controls'].forEach((controls) => {
      if (controls.touched) {
        touched = true;
      }
    });
    this.form.controls['actions']['controls'].forEach((controls) => {
      if (controls.touched) {
        touched = true;
      }
      if (controls.controls.messages.touched) {
        touched = true;
      }
      controls.controls.messages.controls.forEach((c) => {
        if (c.touched) {
          touched = true;
        }
      });
    });
    if ((!this.form || (!this.form.touched && !touched)) && this.action === 'edit') {
      return true;
    }
    return this._dialogService.openConfirm({
      message: this._translateService.instant('confirm_leave'),
      disableClose: false,
      title: ucFirst(this._translateService.instant('unsave_changes')),
      acceptButton: ucFirst(this._translateService.instant('leave')),
      cancelButton: ucFirst(this._translateService.instant('stay')),
    }).afterClosed();
  }
}
