import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UserService} from '../../../services/user.service';
import {Title} from '@angular/platform-browser';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../services/navigation.service';
import {DriverService} from '../../../services/driver.service';

@Component({
  selector: 'app-connect-email',
  templateUrl: './connect-email.component.html',
  styleUrls: ['./connect-email.component.scss']
})
export class ConnectEmailComponent implements OnInit {
  userId: string;
  driverUserId: string;
  translations: string[];
  subTitle: string;
  drivers: any[];
  selectedDriver: any;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _userService: UserService,
    private _driverService: DriverService,
    private _titleService: Title,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    private _navigationService: NavigationService
  ) {
    const self = this;

    _translateService.get(['connect_drivers_to_user_subtitle', 'connect_drivers_to_user_title', 'connect_drivers_to_user_success_title', 'connect_drivers_to_user_success_text', 'ok', 'connect_drivers_connectyda_confirmed', 'connect_drivers_to_user_duplicate_failed_title', 'connect_drivers_to_user_duplicate_failed_text'])
      .subscribe((translations: any) => {
        self.translations = translations;
        self._titleService.setTitle(translations['connect_drivers_to_user_title']);
        self._navigationService.setBodyTitle(translations['connect_drivers_to_user_title']);

        this._route.params.subscribe(params => {
          if (params['token']) {
            self.userId = params['token'];

            this._userService.get(self.userId, {'include': 'Drivers'})
              .subscribe((user) => {
                this._driverService.getAll({'where': {'originalEmail': user.email}})
                  .subscribe((drivers) => {
                    this.subTitle = translations['connect_drivers_to_user_subtitle'].replace('{emailaddress}', user.email)
                    this.drivers = drivers;
                    console.log(this.drivers);
                    if (drivers[0]) {
                      this.selectedDriver = drivers[0].userId;
                    }

                    if (user.connectStatus === 'confirmed') {
                      self._dialogService.openAlert({
                        message: self.translations['connect_drivers_connectyda_confirmed'],
                        disableClose: true,
                        closeButton: self.translations['ok'],
                      }).afterClosed().subscribe(() => {
                        this._router.navigate([`/login`]);
                      });
                    }
                  });
              });
          }
        });
      });
  }

  ngOnInit(): void {

  }

  toggleSelection(item: any): void {
    this.selectedDriver = item;
  }

  confirmConnection(): void {
    const self = this;
    if (this.userId === this.selectedDriver) {
      console.log('something is wrong selected and user are the same!');
    } else {
      this._userService.connectPortalUser(this.userId, this.selectedDriver)
        .subscribe((result) => {
          this._dialogService.openAlert({
            title: self.translations['connect_drivers_to_user_success_title'],
            message: self.translations['connect_drivers_to_user_success_text'],
            disableClose: true,
            closeButton: self.translations['ok'],
          }).afterClosed().subscribe(() => {
            this._router.navigate([`/login`]);
          });
        }, (error) => {
          console.log(error.error.error);
          if(error.error.error.statusCode === 422) {
            if(error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.username) {
              this._dialogService.openAlert({
                title: self.translations['connect_drivers_to_user_duplicate_failed_title'],
                message: self.translations['connect_drivers_to_user_duplicate_failed_text'],
                disableClose: true,
                closeButton: self.translations['ok'],
              }).afterClosed().subscribe(() => {

              });
            }
          }

        });
    }
  }

  rejectConnection(): void {
    this._userService.rejectPortalUser(this.userId, {connectStatus: 'rejected'})
      .subscribe((result) => {
        this._router.navigate([`/login`]);
      });
  }

  save(selection): void {
    if (selection === 'ignore') {
      this.rejectConnection();
    } else {
      this.confirmConnection();
    }
  }
}
