import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {Clipboard} from '@angular/cdk/clipboard';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DispatchDialogComponent} from '../../../../../shared/dispatchpanel/parts/dispatch-dialog/dispatch-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ics-calender',
  templateUrl: './ics-calender.component.html',
  styleUrls: ['./ics-calender.component.scss']
})
export class IcsCalenderComponent implements OnInit {
  @Input() id;
  @Input() type;
  calenderUrl: string;
  translations: string[];

  constructor(
    private clipboard: Clipboard,
    private _translateService: TranslateService,
    private _dialogRef: MatDialogRef<DispatchDialogComponent>,
    public _snackBar: MatSnackBar,
  ) {
    _translateService.get([
      'link_copied',
      'ok'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    if (this.type === 'driver') {
      this.calenderUrl = `${environment.apiBaseUrl}/driver/calendar/${this.id}/`;
    } else {
      this.calenderUrl = `${environment.apiBaseUrl}/calendar/${this.id}/`;
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.calenderUrl);
    this._snackBar.open(ucFirst(this.translations['link_copied']), this.translations['code_copied'], {
      duration: 3000
    });
  }
}
