import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Ride} from '../../../../../../../models/ride';
import {Product} from '../../../../../../../models/product';
import {PriceService} from '../../../../../../../services/price.service';
import {DaAppInstallService} from '../../../../../../../services/da-app-install.service';
import {RideEditPriceComponent} from '../ride-edit-price/ride-edit-price.component';
import {ProductService} from '../../../../../../../services/tps/product.service';
import {UtilityService} from '../../../../../../../services/utility.service';
import {Company} from '../../../../../../../models/company';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';

@Component({
  selector: 'app-ride-edit-product',
  templateUrl: './ride-edit-product.component.html',
  providers: [ProductService],
  styleUrls: ['./ride-edit-product.component.scss']
})
export class RideEditProductComponent implements OnInit, DoCheck {
  @Input() context: string;
  @Input() form: UntypedFormGroup;
  @Input() ride: Ride;
  @Input() mode: string;
  @Output() pricingError = new EventEmitter<RideEditPriceComponent>();
  calculated = false;
  company: Company;
  myProducts: any[];
  products: any[];
  localRide: any;
  loaded = false;
  selectCurrentProduct: string;
  private firstLoad = false;
  @Output() productChanged = new EventEmitter<RideEditPriceComponent>(this.firstLoad);

  constructor(
    private _priceService: PriceService,
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _productService: ProductService,
    private _daAppInstallService: DaAppInstallService
  ) {

  }

  ngOnInit(): void {
    const self = this;
    if (this.mode === 'edit' && !this.ride.id) {
      setTimeout(function () {
        self.ngOnInit();
      }, 200);
      return;
    }

    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    self.loadProducts();
    // if (this.mode === 'edit') {
    //   this.localRide = this.cleanupRide(JSON.parse(JSON.stringify(this.ride)));
    //   this.calculatePrices(true);
    // }
    // else {
    //   setTimeout(function() {
    //     self.ngOnInit();
    //   }, 500);
    // }
  }

  loadProducts(): void {
    const self = this;
    self._productService.getAll({order: 'priority ASC'}).subscribe((myProducts: Product[]) => {
      self.myProducts = myProducts;
      self.products = myProducts;
      self.form.controls['product'].setValue(self.products[0]);
      self.loaded = true;
      self.productChanged.emit();
    });
  }

  calculatePrices(selectFirst = false): void {
    const self = this;
    const nRide = JSON.parse(JSON.stringify(this.localRide));
    if (this.company) {
      this._daAppInstallService.getAll({'where': {companyId: this.company.id}})
        .subscribe((daApps) => {
          let webbookerDaApp = daApps.filter((d) => {
            return (d.daAppId === environment.daAppId ? d : false)
          })[0];

          if (!webbookerDaApp) {
            let webbookerDaApp = daApps.filter((d) => {
              return (d.formId ? d : false)
            })[0];
          }

          if (!webbookerDaApp && daApps) {
            webbookerDaApp = daApps[0];
          }
          if (webbookerDaApp) {
            nRide.daAppInstallId = webbookerDaApp.id;
            nRide.origin = (webbookerDaApp.formId ? webbookerDaApp.formId : webbookerDaApp.daAppId);
          }

          nRide.callOrigin = 'portal';
          delete nRide.id;

          if (this.form.controls['priceMethod'].value === 'onMeter' && this._vault.getItem(`${this.company.id}_hasBaseRule`)) {
            nRide.taxiMeter = true;
          }

          self._priceService.calculatePrice(nRide).subscribe(
            (products) => {
              self.products = [];
              const nProducts = [];
              let found = false;

              if (self.myProducts && products && products.length > 0) {
                self.myProducts.forEach((p) => {
                  found = false
                    products.forEach((p2) => {
                      if (p._id === p2.productId) {
                        found = true;
                        self.products.push(p2);
                      }
                    });
                    if (!found) {
                      self.products.push({
                        category: '',
                        description: '',
                        extraInfo: '',
                        dispatchTypes: null,
                        imagePath: 'https://de122c61b7b8d58cef8d-e55b398e1e03bf373283e24167356a82.ssl.cf3.rackcdn.com/products/5c5d733b8002e060903f46bf/5f6f1700c1628f3b887debd6/product.png',
                        isAllowedOnMeter: true,
                        isFixed: false,
                        maxPassengers: p.maxPassengers,
                        name: p.name,
                        sku: p.type,
                        price: {breakdown: {}, currency: 'EUR', total: 0},
                        priceReturn: {currency: 'EUR', total: 0, breakdown: {}},
                        priority: null,
                        vehicleType: p.type
                      });
                    }
                });
              } else if (self.myProducts) {
                self.products = self.myProducts;
              }
              if (self.ride && self.ride.products) {
                const vehicleProduct = self.ride.products.filter((p) => {
                  return (p.category === 'vehicleType')
                })[0];

                if (vehicleProduct) {
                  const selectedProduct = self.products.filter((p) => {
                    return (p.name === vehicleProduct.description)
                  })[0];
                  // if (selectedProduct) {
                  //
                  // }
                  self.form.controls['product'].setValue((selectedProduct));
                  self.productChanged.emit();
                }
              } else if (selectFirst || !self.form.controls['product'].value) {
                self.form.controls['product'].setValue(self.products[0]);
                self.productChanged.emit();
              } else {
                const product = self.products.filter((p: Product) => {
                  return (p.sku === self.form.controls['product'].value.sku || p._id === self.form.controls['product'].value.productId);
                })[0];
                self.form.controls['product'].setValue(product);
                self.productChanged.emit();
              }
            },
            (error) => {
              if (error.error.error.statusCode === 422) {
                self.pricingError.emit(error.error.error);
              } else if (self.products) {
                if (selectFirst) {
                  self.form.controls['product'].setValue(self.products[0]);
                } else {
                  const product = self.products.filter((p: Product) => {
                    return (p.sku === self.form.controls['product'].value.sku);
                  })[0];
                  self.form.controls['product'].setValue(product);
                  self.productChanged.emit();
                }
              }
            }
          );
        })
    }
  }

  cleanupRide(ride): any {
    if (ride.departure) {
      if (ride.departure.gps && !ride.departure.gps.lat) {
        delete ride.departure;
      } else {
        delete ride.departure.placeId;
        if (!ride.departure.code) {
          delete ride.departure.code;
        }
      }
      if (ride.departure && !ride.departure.countryCode && this.company) {
        ride.departure.countryCode = this.company.country;
      }
    }

    if (ride.destination) {
      if (ride.destination.gps && !ride.destination.gps.lat) {
        delete ride.destination;
      } else {
        delete ride.destination.placeId;
        if (!ride.destination.code) {
          delete ride.destination.code;
        }
      }
      if (ride.destination && !ride.destination.countryCode && this.company) {
        ride.destination.countryCode = this.company.country;
      }
    }
    return {
      // 'daAppInstallId': '',
      'id': ride.id,
      'departure': ride.departure,
      'destination': ride.destination,
      'requestedDate': ride.requestedDate,
      // 'requestedScheduleDate': Joi.date().iso(),
      // 'requestedDestinationDate': Joi.date().iso(),
      'meta': ride.meta,
      'foreignMeta': ride.foreignMeta,
      'passengerCount': ride.passengerCount,
    };
  }

  ngDoCheck(): void {
    if (this.mode === 'edit' && !this.ride.id) {
      return;
    }

    const controlObj = {};
    Object.keys(this.form.controls).forEach((k) => {
      controlObj[k] = this.form.controls[k].value;
    });

    const ride = this.cleanupRide(JSON.parse(JSON.stringify(controlObj)));
    const hash = JSON.stringify(ride);
    const chash = JSON.stringify(this.localRide);
    if (
      ((!this.ride.id || this.form.controls['priceMethod'].value === 'onMeter') ||
        (this.ride.id && this.loaded)) &&
      this.loaded &&
      hash !== chash && (
        ride.departure &&
        ride.departure.gps &&
        ride.departure.gps.lat
      )) {
      const selectFirst = (!this.localRide && !this.ride);
      this.localRide = ride;
      this.calculatePrices(selectFirst);
    }
  }
}
