import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../../../../models/company';

const setTime = (date: Date, time: string) => {
  const parts = time.split(':');
  date.setHours(parseInt(parts[0], 10));
  date.setMinutes(parseInt(parts[1], 10));
  return date;
}

const getTime = (date: Date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  const toStringRep = (int) => int < 10 ? '0' + int : '' + int;
  return toStringRep(h) + ':' + toStringRep(m);
}

@Component({
  selector: 'app-ride-edit-requesteddate',
  templateUrl: './ride-edit-requesteddate.component.html',
  styleUrls: ['./ride-edit-requesteddate.component.scss']
})
export class RideEditRequesteddateComponent implements AfterViewInit {
  @Output() newRequestedDateEvent = new EventEmitter<string>();
  @Output() newBookedDurationEvent = new EventEmitter<string>();
  @Input() requestedDate: Date;
  @Input() requestedDateTime: string;
  @Input() form: UntypedFormGroup;
  @Input() formErrors: any;
  company: Company;

  constructor(
    private route: ActivatedRoute,
  ) {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;
  }

  ngAfterViewInit() {

  }

  setRequestedDateTime() {
    console.log(this.requestedDate);
    if (this.company && this.company.dateFormat) {
      this.requestedDateTime = moment(this.requestedDate).format(this.company.dateFormat + 'THH:mm');
    } else {
      this.requestedDateTime = moment(this.requestedDate).format('YYYY-MM-DDTHH:mm');
    }
    console.log(this.requestedDateTime);

  }

  setRequestedDate($event) {
    const requestedDate = moment(this.form.controls['requestedDate'].value).toISOString();
    this.newRequestedDateEvent.emit(requestedDate);
  }

  bookedDurationChange() {
    // @ts-ignore
    this.newBookedDurationEvent.emit(this.form.controls['priceMeta'].controls['bookedDuration'].value);
  }
}
