<div class="card-pane">
  <mat-card-title>{{'cloud_connect_googlekey_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle class="push-bottom">{{'cloud_connect_googlekey_helptext'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <br>
  <form class="full-width" flex="100" [formGroup]="form">
    <mat-list-item class="push-top" flex-gt-sm="100">
      <mat-form-field class="licenseKeyInput value full-width ng-invalid">
        <input [(ngModel)]="this.googleClientSecret" [value]="this.googleClientSecret" class="mat-error input-warning"
               autocomplete="off"
               matInput
               formControlName="googleClientSecret"
               placeholder="{{ 'google_key' | translate | ucFirst }}"
               type="text">
      </mat-form-field>
    </mat-list-item>

    <mat-list-item class="flow-root push-top push-bottom" flex-gt-sm="100">
      <mat-checkbox (change)="toggleSecured()" [checked]="hasSecured">{{'apikey_secured_checkbox'|translate|ucFirst}}</mat-checkbox>
      <div>
        {{'apikey_secured'|translate|ucFirst}}
      </div>
    </mat-list-item>

    <mat-list-item class="push-top" flex-gt-sm="100" *ngIf="hasSecured">
      <mat-form-field class="licenseKeyInput value full-width">
        <input [(ngModel)]="this.backendKey" [value]="this.backendKey"
               autocomplete="off"
               matInput
               formControlName="backendKey"
               placeholder="{{ 'backend_key' | translate | ucFirst }}"
               type="text">
      </mat-form-field>
    </mat-list-item>


    <mat-divider></mat-divider>
  </form>

  <mat-card-actions class="full-width" flex="100">
    <img alt="" class="plan-img" src="/assets/yda/powered-by-google.png">


    <button (click)="this.updateGoogleKey()" class="push-top" color="primary" flex
            mat-raised-button>
      {{'save'|translate|ucFirst}}</button>

    <button (click)="dialogRef.close()" mat-flat-button>
      {{ 'close' | translate | ucFirst }}
    </button>

    <a (click)="this.openManual()" class="button-link push-top yda-link">{{'howto_setup_google_key'|translate|ucFirst}}</a>

  </mat-card-actions>


</div>
