import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Driver} from '../models/driver';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'localizedCurrency'
})
export class LocalizedCurrencyPipe implements PipeTransform {
  country = 'nl';

  constructor(private cp: CurrencyPipe, private vault: CoolLocalStorage) {
    const driver: Driver = vault.getObject(`${environment.vaultPrefix}.driver`);
    if (driver && driver.country) {
      this.country = driver.country.toLowerCase();
    }
  }

  transform(value: any, args?: any): any {
    const argsArray = (args ? args.split(':') : []);
    let transformValue;
    if (argsArray[0]) {
      transformValue = value.breakdown[argsArray[0]].total;
    } else if (argsArray[1]) {
      transformValue = value[argsArray[1]];
    } else {
      transformValue = value.total;
    }
    const currency = (argsArray[2] ? argsArray[2] : value.currency);
    return this.cp.transform((transformValue / 100), currency, 'symbol-narrow', '1.2-2');
  }

}

@NgModule({
  providers: [CurrencyPipe],
  declarations: [LocalizedCurrencyPipe],
  exports: [LocalizedCurrencyPipe]
})
export class LocalizedCurrencyPipeModule {
}
