import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Address} from '../../../../../../../models/address';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Rx';
import {concat, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {FieldErrorService} from '../../../../../../../services/field-error.service';
import {CountryService} from '../../../../../../../services/country.service';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../../../../models/company';
import {UtilityService} from '../../../../../../../services/utility.service';

declare var $: any;
declare var google: any;
declare var accent_fold: any;
declare var swal: any;

@Component({
  selector: 'app-ride-edit-address',
  templateUrl: './ride-edit-address.component.html',
  styleUrls: ['./ride-edit-address.component.scss']
})
export class RideEditAddressComponent implements AfterViewInit, OnInit {
  @ViewChild('autoCompleteServiceHolder') autoCompleteServiceHolder;
  @Input() address: Address;
  @Input() placeholder: string;
  @Input() direction: string;
  @Input() form: UntypedFormGroup;
  @Input() id = 'test';
  @Input() label = 'test';
  @Input() formControlItem: UntypedFormControl;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  company: Company;
  results: Observable<any>;
  loading = false;
  hasNew = false;
  searchTerm = '';
  context: string;
  houseNumberRequired = true;
  input = new Subject<string>();
  geocodeService: any;
  autocompleteService: any;
  autocompleteSessionToken: string;
  autocompleteSettings = {
    componentRestrictions: {}
  };
  autoComplete = new UntypedFormControl();
  data: any[] = [];
  hasErrors = false;

  constructor(private thisElement: ElementRef,
              private _route: ActivatedRoute,
              private _countryService: CountryService,
              public errors: FieldErrorService) {
    const self = this;
    this.autoComplete.valueChanges.pipe(
      debounceTime(150))
      .subscribe(q => this.search(q))

    this.context = this._route.routeConfig['context'] || 'driver';
    let country;
    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      country = this.company.country;
    } else if (this.context === 'driver') {

    }

    if (country) {
      this._countryService.getAll({'where': {'alpha2Code': country}})
        .subscribe((countries) => {
          if (countries && countries[0] && countries[0].autoCompleteRestrictions) {
            self.autocompleteSettings = {
              componentRestrictions: {
                country: countries[0].autoCompleteRestrictions
              }
            }
          } else if (countries && countries[0]) {
            self.autocompleteSettings = {
              componentRestrictions: {
                country: country
              }
            }
          }
        });
    }
  }

  ngOnInit() {
    if (typeof (google) === 'undefined') {
      const _self = this;
      setTimeout(function () {
        _self.ngOnInit();
      }, 150);
    } else {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
      this.geocodeService = new google.maps.Geocoder();
      // @ts-ignore
      this.results = concat(
        of([]), // default items
        this.input.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.loading = true),
          switchMap(term => this.searchGoogle(term)),
          tap(() => this.loading = false)
        )
      );
    }
  }

  ngAfterViewInit(): void {
    const input = document.querySelector(`#${this.direction}-input input[type=text]`);
    if (input) {
      input.setAttribute('type', 'search');
    }
  }

  searchGoogle(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      if (!term) {
        return resolve([]);
      }
      if (!this.autocompleteService) {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
        this.geocodeService = new google.maps.Geocoder();
      }
      this.autocompleteService.getPlacePredictions({
        ...this.autocompleteSettings,
        input: term,
        sessionToken: this.autocompleteSessionToken
      }, (predictions: any[]) => {
        const results = [];
        if (predictions && predictions.length > 0) {
          predictions.forEach(prediction => {
            prediction.types.push('google');
            results.push(prediction);
          });
        }
        return resolve(results || []);
      });
    });
  }

  geoCodeAddress(prediction: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.geocodeService.geocode({placeId: prediction.place_id}, (results, status) => {
        if (status === 'OK') {
          const data = results[0];
          // console.log(`getAddress() - prediction`, prediction);
          // console.log(`getAddress() - data`, data);
          const address: any = {};
          address.gps = {};
          address.gps.lat = data.geometry.location.lat();
          address.gps.lng = data.geometry.location.lng();
          address.internationalAlias = prediction.description;
          address.city = '';
          address.streetName = '';
          address.houseNumber = '';
          address.postalCode = '';

          let lowestAdminLvl;
          if (data.address_components) {
            data.address_components.forEach((component) => {
              if (component.types[0] === 'postal_code' || component.types[0] === 'postal_code_prefix') {
                address.postalCode = component.short_name.replace(' ', '');
              }
              if (component.types[0] === 'locality') {
                address.city = component.short_name;
              }
              if (component.types[0] === 'country') {
                address.countryCode = component.short_name;
              }
              if (component.types[0] === 'route') {
                address.streetName = component.short_name;
              }
              if (component.types[0] === 'street_number') {
                address.houseNumber = component.short_name.replace(' ', '');
              }
              if (component.types[0] === 'administrative_area_level_3' && !lowestAdminLvl) {
                lowestAdminLvl = component.short_name;
              }
              if (component.types[0] === 'administrative_area_level_2' && !lowestAdminLvl) {
                lowestAdminLvl = component.short_name;
              }
              if (component.types[0] === 'administrative_area_level_1' && !lowestAdminLvl) {
                lowestAdminLvl = component.short_name;
              }
            });
          }
          if (!address.city) {
            address.city = lowestAdminLvl;
          }
          return resolve(address);
        } else {
          return reject('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  addressChanged() {
    if (this.autoComplete.value === '') {
      this.address = new Address();
      const patch = {};
      patch[this.direction] = this.address;
      this.form.patchValue(patch);
      this.valueChanged.emit(patch);
      this.checkFieldValidity();
    }
  }

  getAddress(prediction: any) {
    console.log(`getAddress() - prediction`, prediction);
    if (typeof prediction === 'string') {
      return null;
    }
    const address: any = {
      type: prediction.type || 'generic',
      code: prediction.code,
      gps: {}
    };
    if (prediction.types && prediction.types.includes('point_of_interest') && prediction.structured_formatting.main_text) {
      address.type = 'poi';
      address.synonym = prediction.structured_formatting.main_text;
    }
    if (!prediction.custom) {
      this.geoCodeAddress(prediction).then(result => {
        address['placeId'] = prediction['place_id'];
        Object.keys(result).forEach((key) => {
          address[key] = result[key];
        });
        this.address = address;

        const patch = {};
        patch[this.direction] = this.address;
        this.valueChanged.emit(patch);
        this.form.patchValue(patch);
        this.checkFieldValidity();
      });
    } else {
      address.gps.lat = prediction.gps.lat;
      address.gps.lng = prediction.gps.lng;
      address.internationalAlias = prediction.formatted_address;
      this.address = address;
      this.valueChanged.emit(this.address);
      const patch = {};
      patch[this.direction] = this.address;
      this.form.patchValue(patch);
      this.checkFieldValidity();
    }
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  checkFieldValidity(): void {
    if (this.houseNumberRequired && !this.address.houseNumber && this.address.type === 'generic') {
      this.errors.addError({
        id: `${this.direction}HouseNumber`,
        property: this.direction,
        text: 'address_field_error_house_number',
        label: this.label,
      });
    } else {
      this.errors.removeError(`${this.direction}HouseNumber`);
    }
    if (this.form.controls[this.direction].value) {
      this.errors.removeError(`${this.direction}Required`);
    }
  }

  search(term: string) {
    const self = this;
    self.searchTerm = term;
    if (term.length > 3 && self.loading === false) {
      self.loading = true;
      setTimeout(function () {
        self.data = [];
        let googleResults = [];
        self.searchGoogle(self.searchTerm)
          .then(results => {
            googleResults = results;
            self.data = self.data.concat(googleResults);
            self.loading = false;
          })
      }, 300);
    }
  }

  clear(): void {
    const patch = {};
    patch[this.direction] = '';
    patch[`${this.direction}Model`] = null;
    this.form.patchValue(patch);
  }

  convertAirportToPoi(airport: any) {
    return {
      ...airport,
      custom: true,
      description: `${airport.name} (${airport.code})`,
      formatted_address: `${airport.name} (${airport.code})`,
      type: 'airport',
      types: [
        'airport',
        'airportPoi'
      ],
    };
  }

  searchAirports(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      const results = [];
      const regex = new RegExp(term, 'gi');
      // AIRPORTS.forEach(airport => {
      //   const nameMatch = airport.name.match(regex);
      //   const codeMatch = airport.code.match(regex);
      //   if (nameMatch) {
      //     results.push(this.convertAirportToPoi(airport));
      //   } else if (codeMatch) {
      //     results.push(this.convertAirportToPoi(airport));
      //   }
      // });
      // return resolve(results);
      return resolve([]);
    });
  }

  getIcon = (label: string): string => ({
    address: 'place',
    street_address: 'place',
    airport: 'local_airport',
    hospital: 'local_hospital',
    hotel: 'local_hotel',
    railway_station: 'train',
    city: 'location_city',
    country: 'language',
    neighborhood: 'local_florist',
    province: 'flag',
    state: 'account_balance',
    other: 'place',
  }[label] || 'place');
}
