import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompanyService} from '../../../../../../services/company.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Company} from '../../../../../../models/company';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment/moment';

@Component({
  selector: 'app-update-google-key-dialog',
  templateUrl: './update-google-key-dialog.component.html',
  styleUrls: ['./update-google-key-dialog.component.scss']
})
export class UpdateGoogleKeyDialogComponent implements OnInit {
  googleClientSecret: string;
  googleClientId: string;
  backendKey: string;
  active: boolean;
  hasSecured = false;
  company: Company;
  form: UntypedFormGroup;
  translations: string[];

  constructor(
    private _companyService: CompanyService,
    private _snackbar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<UpdateGoogleKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.googleClientSecret = data.parent.googleClientSecret;
    this.googleClientId = data.parent.googleClientId;
    this.backendKey = data.parent.backendKey;
    this.active = data.parent.active;
    this.company = data.parent.company;
    this.translations = data.parent.translations;

    if (this.backendKey) {
      this.hasSecured = true;
    }
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      'googleClientSecret': [this.googleClientSecret, [Validators.required, Validators.pattern(/AIza[0-9A-Za-z\\-_]{35}/)]],
      'backendKey': [this.backendKey, [Validators.pattern(/AIza[0-9A-Za-z\\-_]{35}/)]],
    }, {validators: []});
  }


  updateGoogleKey(): void {
    const googleKey = {
      'active': (this.googleClientSecret && this.googleClientSecret !== '' ? true : false),
      'backendKey': this.backendKey,
      'clientSecret': this.googleClientSecret,
      'clientId': this.googleClientId
    }

    this._companyService.update(this.company.id, {'googleKey': googleKey}).subscribe((result) => {
      if (result.error) {
        this._snackbar.open(this._translateService.instant('cloud_connect_googlekey_error'), '', {duration: 3000});
      } else {
        this._snackbar.open(this._translateService.instant('cloud_connect_googlekey_updated'), '', {duration: 3000});
      }
    })
    this.data.parent.company.googleKey = googleKey;
    this.data.parent.loadData();
    this.dialogRef.close();
    return;
  }

  toggleSecured() {
    this.hasSecured = !this.hasSecured;
  }

  openManual() {
    window.open('https://intercom.help/yourdriverapp/en/articles/8937596-google-maps-api-billing-account', '_blank');
  }

  loginToGoogle() {
    window.open('https://console.developers.google.com/apis/credentials', '_blank');
  }
}
