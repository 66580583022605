import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute, Router} from '@angular/router';
import {RideService} from '../../../../../services/ride.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../../environments/environment';
import {Ride} from '../../../../../models/ride';
import {GeoPoint} from '../../../../../models/geo-point';
import {NavigationService} from '../../../../../services/navigation.service';
import {Rating} from '../../../../../models/rating';
import {RatingService} from '../../../../../services/rating.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GpsLogEntry} from '../../../../../models/gps-log-entry';
import {Shift} from '../../../../../models/shift';
import {Driver} from '../../../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DriverService} from '../../../../../services/driver.service';
import moment from 'moment';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader, MarkerManager} from '@agm/core';
import {OfferService} from '../../../../../services/offer.service';
import {GoogleMapConfig} from '../../../../googleMapConfig';
import {CustomLazyAPIKeyLoader} from '../../../../../services/CustomLazyAPIKeyLoader';
import {PriceBreakdown} from '../../../../../models/price-breakdown';
import {Price} from '../../../../../models/price';
import {ValidateAllFormFields} from '../../../../../functions/validateAllFormFields';
import {Passenger} from '../../../../../models/passenger';
import {RideEditPriceComponent} from './parts/ride-edit-price/ride-edit-price.component';

function pad(num, size) {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

const fromCents = (value: number) => (value / 100).toFixed(2);

@Component({
  selector: 'app-ride-edit',
  templateUrl: './ride-edit.component.html',
  styleUrls: ['./ride-edit.component.scss'],
  providers: [
    RideService,
    RatingService,
    MarkerManager,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class RideEditComponent extends ValidateAllFormFields implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RideEditPriceComponent) child: RideEditPriceComponent;
  loaderName = 'ride';
  context: string;
  driver: Driver;
  quotationError: any;
  ownerName: string;
  companyId: string;
  id: string;
  action: string;
  actionTranslated: string;
  rideId: string;
  iconUrl: string;
  translations: string[] = [];
  departureIconUrl: string;
  destinationIconUrl: string;
  mapLineColor: string;
  hasPassenger = false;
  hasPassengerRating = false;
  hasOperatorRating = false;
  loadDataDone = false;
  mapInstance: any;
  company: any;
  mapCenter: GeoPoint = new GeoPoint();
  ride: Ride = new Ride();
  newRide: Ride;
  members: Driver[] = [];
  shift: Shift = new Shift();
  gpsLogEntries: GpsLogEntry[] = [];
  passengerRating: Rating = new Rating();
  operatorRating: Rating = new Rating();
  operatorRatingSaved = '';
  passenger: Passenger;
  form: UntypedFormGroup;
  currencySymbol: string;
  currency;
  startGps: any = {
    lat: 0,
    lng: 0,
    zoom: 2
  };
  driverId: string;
  isOwner = true;
  isDriver = false;
  formErrors: any = {
    departure: {
      city: '',
      streetName: '',
      internationalAlias: '',
      gps: {
        lat: '',
        lng: ''
      }
    },
    requestedDate: '',
  };
  validationMessages = {
    'departure-gps-lat': {
      'fieldName': 'departure',
      'required': 'form_validation_required',
    },
    'passenger-fname': {
      'fieldName': 'firstname',
      'required': 'form_validation_required',
    },
    'passenger-lname': {
      'fieldName': 'lastname',
      'required': 'form_validation_required',
    },
    'passenger-phoneNumber': {
      'fieldName': 'phoneNumber',
      'required': 'form_validation_required',
    },
    'passenger-email': {
      'fieldName': 'email',
      'required': 'form_validation_required',
    },
    'requestedDate': {
      'fieldName': 'requestedDate',
      'required': 'form_validation_required',
    },
  };
  private _config: any;
  private currentAction: string;

  constructor(@Inject(LAZY_MAPS_API_CONFIG) config: any,
              public media: TdMediaService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              public snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private _dialogService: TdDialogService,
              private _loadingService: TdLoadingService,
              private _router: Router,
              private rideService: RideService,
              private driverService: DriverService,
              private ratingService: RatingService,
              private _translateService: TranslateService,
              private navigationService: NavigationService,
              private vault: CoolLocalStorage,
              private offerService: OfferService,
              private titleService: Title) {
    super();
    this.loadErrorTranslations(_translateService);

    this.context = this.route.routeConfig['context'] || 'driver';

    this._loadingService.register('ride.details');
    this._loadingService.register('ride.map');

    this.startGps = this.vault.getObject(`${environment.vaultPrefix}.position`);
    if (!this.startGps) {
      this.startGps = {
        lat: 0,
        lng: 0,
        zoom: 2
      };
    }

    const params: any = this.activatedRoute.snapshot.params;
    const parentParams: any = this.activatedRoute.parent.snapshot.params;
    this.rideId = params.id;
    this.action = (params.action ? params.action : 'edit');
    this.companyId = parentParams.id;
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);

    this.iconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.departureIconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.destinationIconUrl = `/assets/${environment.buildName}/icon-destination-marker.png`;
    this.mapLineColor = `${environment.mapLineColor}`;
    if (this.context === 'company') {
      const {company} = this.route.parent.snapshot.data;
      this.company = company;
      this.currency = this.company.currency;
    } else {
      this.currency = 'EUR';
    }
    if (this.driver) {
      this.driverId = this.driver.id;
    }

    if (this.context === 'company') {
      this.ownerName = this.company.name;
    } else {
      this.ownerName = this.translations['me'];
    }

    _translateService.get(['job_details', 'jobs', 'operator_rating_saved', 'save', 'saved', 'dispatch_log', 'job_added_title', 'job_added_message', 'me', 'job_canceled', 'confirm_job_cancel_message', 'confirm_job_cancel_title', 'return_to_job', 'cancel_job', 'job_dispatched', 'ok', 'save_job_error', 'yes', 'no', 'paymentmethodid_no_option_selected', 'payment_status_pickup_at', 'paymentmethod_group_pricing_rules_error'])
      .subscribe((translations: any) => {
        this.titleService.setTitle(translations['job_details'] + environment.windowTitleSuffix);
        this.navigationService.setBodyTitle(translations['job_details']);
        this.operatorRatingSaved = translations['job_details'];
        this.translations = translations;
        this.actionTranslated = translations['save'];

        if (this.context === 'company') {
          navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': this.translations['dispatchpanel'],
              'link': `/groups/${this.companyId}/dispatchpanel/`,
              'queryParams': {'return': this.activatedRoute.snapshot.queryParams['return']}
            }
          ]);
        }
      });

    this.findMe();
    this.initForm();
  }

  pricingError(error) {
    this.quotationError = error;
  }

  newDriverEventHander($event?: any) {
    this.loadData();
  }

  addessUpdated($event: any) {

  }

  newRequestedDateEvent($event: any) {
    this.ride.requestedDate = $event;
  }

  newBookedDurationEvent($event: any) {
    this.child.hourlyRateUpdated();
  }

  onValueChanged() {
    this.validateAllFormFields(this.form);
  }

  onOwnerNameChanged($event: any) {
    this.ownerName = $event;
  }

  ngOnInit() {
    /**
     * Subscribe to value changes in the form
     */
    this.onValueChanged();
    this.form.valueChanges.subscribe(() => this.onValueChanged());
  }

  initForm() {
    this.form = this.formBuilder.group({
      ownerId: [(this.context === 'company' ? this.company.id : this.driver.id), [Validators.required]],
      ownerType: [(this.context === 'company' ? 'Company' : 'Driver'), [Validators.required]],
      isHourlyBooking: [false, []],
      departure: this.formBuilder.group({
        city: ['', []],
        streetName: [null, []],
        houseNumber: [null, []],
        postalCode: [null, []],
        internationalAlias: [null, [Validators.required]],
        synonym: [null, []],
        placeId: [null, []],
        countryCode: [null, []],
        code: ['', []],
        gps: this.formBuilder.group({
          lng: [null, [Validators.required]],
          lat: [null, [Validators.required]],
        }),
        type: [null, []],
      }),
      destination: this.formBuilder.group({
        city: ['', []],
        streetName: [null, []],
        houseNumber: [null, []],
        postalCode: [null, []],
        internationalAlias: [null],
        synonym: [null, []],
        placeId: [null, []],
        countryCode: [null, []],
        code: ['', []],
        gps: this.formBuilder.group({
          lng: [null, []],
          lat: [null, []],
        }),
        type: [null, []],
      }),
      passengerCount: [1, [Validators.min(1), Validators.required]],
      requestedDate: [new Date(), [Validators.required]],
      vehicleType: ['saloon', [Validators.required]],
      debtorId: [null, []],
      passengerId: [null, []],
      passengerIsDebtor: [null, []],
      flightNumber: ['', []],
      priceMethod: ['onMeter', []],
      calculationMethod: [(this.context === 'company' ? 'manual' : 'manual'), []],
      language: ['en', []],
      note: ['', []],
      priceMeta: this.formBuilder.group({
        currency: [this.currency, []],
        hourlyRate: ['0', []],
        bookedDuration: ['00:00', []],
      }),
      passenger: this.formBuilder.group({
        fname: [null, []],
        lname: [null, []],
        phoneNumber: [null, []],
        email: [null, [Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
        language: ['en', []],
      }),
      price: this.formBuilder.group({
        total: [0, []],
        currency: [this.currency, []],
        breakdown: [null, []],
      }),
      paymentMeta: this.formBuilder.group({
        origin: ['inTaxi', Validators.required],
        description: ['Payment in taxi', []],
        method: ['unknown', []],
        moment: ['afterRide', []],
        paymentMoment: ['pickuptime', []],
        paymentMethodId: ['select', []],
        hoursBeforePickup: ['4', []],
      }),
      product: [, []],
    }, {validators: []});

    if (this.rideId) {
      this.loadData();
    } else {
      this.ride.requestedDate = new Date();
      this.ride.requestedDateTimeFormat = 'ASAP';

      setTimeout(() => {
        this._loadingService.resolve('ride.details');
        this._loadingService.resolve('ride.map');
        this._loadingService.resolve('ride.ratings');
        this.loadDataDone = true;
      }, 500);
    }
  }

  newPassengerEventHander(passenger) {
    this.passenger = passenger;
  }

  conditionalFormatting() {
    if (this.form.get('paymentMeta').get('origin').value === 'invoice') {
      if (this.form.controls['passengerIsDebtor'].value === true) {
        this.form.controls['debtorId'].setValidators([Validators.required]);
        this.form.get('passenger').get('fname').setValidators([]);
        this.form.get('passenger').get('lname').setValidators([]);
        this.form.get('passenger').get('phoneNumber').setValidators([]);
        this.form.get('passenger').get('email').setValidators([]);
      } else {
        this.form.controls['debtorId'].setValidators([]);
        this.form.get('passenger').get('fname').setValidators([Validators.required]);
        this.form.get('passenger').get('lname').setValidators([Validators.required]);
        this.form.get('passenger').get('phoneNumber').setValidators([Validators.required]);
        this.form.get('passenger').get('email').setValidators([Validators.required]);
      }
    } else {
      this.form.controls['debtorId'].setValidators([]);
      this.form.get('passenger').get('fname').setValidators([]);
      this.form.get('passenger').get('lname').setValidators([]);
      this.form.get('passenger').get('phoneNumber').setValidators([]);
      this.form.get('passenger').get('email').setValidators([]);
    }
    this.form.controls['debtorId'].updateValueAndValidity();
    this.form.get('passenger').get('fname').updateValueAndValidity();
    this.form.get('passenger').get('lname').updateValueAndValidity();
    this.form.get('passenger').get('phoneNumber').updateValueAndValidity();
    this.form.get('passenger').get('email').updateValueAndValidity();
  }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position && position.coords) {
          this.startGps.lat = position.coords.latitude;
          this.startGps.lng = position.coords.longitude;
          this.startGps.zoom = 16;
          this.vault.setObject(`${environment.vaultPrefix}.position`, this.startGps);
        } else {
          console.log('navigator.geolocation not found!', position);
        }
      });
    } else {
      console.log('navigator.geolocation not found!');
    }
  }

  onMarkerDragEnd(event: any, i) {
    const geocoder = new google.maps.Geocoder();
    const self = this;
    geocoder.geocode({location: {lat: event.coords.lat, lng: event.coords.lng}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          self.ride[i].gps = {
            lat: event.coords.lat,
            lng: event.coords.lng,
          };
          self.ride[i].placeId = results[0].place_id;
          self.ride[i].description = results[0].formatted_address;
          self.ride[i].type = 'location';
          // self.geocodePlaceId(results[0].place_id, (addressCode) => {
          //   self.ride[i].addressCode = addressCode;
          // });
        }
      }
    });
  }

  geocodePlaceId(placeId, next) {
    if (!placeId) {
      return next();
    }
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({placeId}, (results, status) => {
      if (status === 'OK') {
        let address: any;
        address = {};
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            const comp = results[0].address_components[i];
            if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
              address.postcode = comp.short_name.replace(' ', '');
            }
            if (comp.types[0] === 'locality') {
              address.city = comp.short_name;
            }
            if (comp.types[0] === 'country') {
              address.country = comp.short_name;
            }
            if (comp.types[0] === 'route') {
              address.address = comp.short_name;
            }
            if (comp.types[0] === 'street_number') {
              address.housenumber = comp.short_name.replace(' ', '');
            }
          }

          address.lng = results[0].geometry.location.lng();
          address.lat = results[0].geometry.location.lat();
        }
        return next(JSON.stringify(address));
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  onMapClicked(event: any) {
    const geocoder = new google.maps.Geocoder();
    const self = this;

    let address = 'departure';
    if ((this.ride.departure.gps && this.ride.departure.gps.lat)) {
      address = 'destination';
    }
    if (!self.ride[address].gps.lat) {
      geocoder.geocode({location: {lat: event.coords.lat, lng: event.coords.lng}}, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            self.ride[address].gps = {
              lat: event.coords.lat,
              lng: event.coords.lng,
            };
            self.ride[address].placeId = results[0].place_id;
            self.ride[address].description = results[0].formatted_address;
            self.ride[address].type = 'location';
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this.navigationService.setSplitLayoutButtons([]);
  }

  mapReady(map): void {
    const self = this;
    this.mapInstance = map;
    if (!self.loadDataDone) {
      if (self.startGps) {
        const start = new google.maps.LatLng(self.startGps.lat, self.startGps.lng);
        map.setCenter(start);
        map.setZoom(self.startGps.zoom);
      }

      setTimeout(function () {
        self.mapReady(map);
      }, 100);
      return;
    }

    if ((self.ride.departure && self.ride.departure.gps && self.ride.departure.gps.lat) ||
      (self.ride.destination && self.ride.destination.gps && self.ride.destination.gps.lat)) {
      setTimeout(function () {
        const bounds = new google.maps.LatLngBounds();

        if (self.ride.departure && self.ride.departure.gps && self.ride.departure.gps.lat) {
          const dep = new google.maps.LatLng(self.ride.departure.gps.lat, self.ride.departure.gps.lng);
          bounds.extend(dep);
        }

        if (self.ride.destination && self.ride.destination.gps && self.ride.destination.gps.lat) {
          const des = new google.maps.LatLng(self.ride.destination.gps.lat, self.ride.destination.gps.lng);
          bounds.extend(des);
        }
        setTimeout(function () {
          map.fitBounds(bounds);
        }, 100);
      }, 100);
    } else if (self.startGps) {
      const start = new google.maps.LatLng(self.startGps.lat, self.startGps.lng);
      map.setCenter(start);
      map.setZoom(self.startGps.zoom);
    }
  }

  loadData(): void {
    const self = this;
    const query = {
      'include': [
        'Vehicle',
        'Driver',
        'Ratings',
        'Passenger',
        'LastJobOffer',
        'Dispatcher',
        'Debtor',
        'PaymentBalance',
        'Payments',
        'Owner',
      ]
    };
    this.rideService.get(this.rideId, query).subscribe((ride: Ride) => {
      ride.intId = (ride.foreignDispatchId ? ride.foreignDispatchId : (ride.foreignBookingId ? ride.foreignBookingId : ride.intId));
      this.ride = this.cleanup(ride);

      if (ride.Passenger) {
        this.hasPassenger = true;
        this.passenger = this.ride.Passenger;

        // @ts-ignore
        this.form.controls['passenger'].controls['email'].disable();
        // @ts-ignore
        this.form.controls['passenger'].controls['lname'].disable();
        // @ts-ignore
        this.form.controls['passenger'].controls['fname'].disable();
        // @ts-ignore
        this.form.controls['passenger'].controls['phoneNumber'].disable();

      } else if (ride.passenger) {
        this.hasPassenger = true;
        this.passenger = this.ride.passenger;
      }

      this.isDriver = (this.ride.driverId === this.driverId);
      this.ride.Ratings.forEach(r => {
        if (r.type === 'operator') {
          this.operatorRating = r;
          this.hasOperatorRating = true;
        }
      });

      this.ride.requestedDateTimeFormat = moment(this.ride.requestedDate).format('YYYY-MM-DDTHH:mm');
      if (this.ride.departure && this.ride.departure.gps) {
        this.ride.departure.gps.lat = Number(this.ride.departure.gps.lat);
        this.ride.departure.gps.lng = Number(this.ride.departure.gps.lng);
      }

      if (this.ride.destination && this.ride.destination.gps) {
        this.ride.destination.gps.lat = Number(this.ride.destination.gps.lat);
        this.ride.destination.gps.lng = Number(this.ride.destination.gps.lng);
      }

      if (this.ride.priceMeta && this.ride.priceMeta.bookedDuration) {
        this.ride.priceMeta.currency = this.currency;
        this.ride.priceMeta.bookedDuration = `${pad((this.ride.priceMeta.bookedDuration / 3600), 2)}:${pad((this.ride.priceMeta.bookedDuration % 3600), 2)}`;
        this.ride.priceMeta.hourlyRate = (this.ride.priceMeta.hourlyRate ? fromCents(this.ride.priceMeta.hourlyRate) : 0);
      }

      if (this.ride.price) {
        // this.ride.price.total = this.ride.price.total / 100;
        this.ride.price.total = fromCents(this.ride.price.total);
        this.ride.price.breakdown = new PriceBreakdown();
      }

      if (this.ride.Debtor && this.ride.Debtor.id) {
        const control = this.form.get('passengerIsDebtor');
        control.setValue(true, {onlySelf: true});
        this.ride.debtorId = 'debtor-' + this.ride.Debtor.id;
      }

      if (this.ride.priceMethod === 'onMeter' && !this.ride.calculationMethod) {
        this.ride.calculationMethod = (this.context === 'company' ? 'group_pricing_rules' : 'manual');
      }
      if (this.ride.passenger && !this.ride.paymentMeta['paymentMethodId']) {
        this.ride.passenger['paymentMethodId'] = 'select';
      }

      if ((!this.ride.passenger || !this.ride.paymentMeta['paymentMethodId'] || this.ride.paymentMeta['paymentMethodId'] === 'select') && this.ride.paymentMeta['origin'] === 'creditcardOnline') {
        this.ride.paymentMeta['origin'] = 'inTaxi';
        this.ride.paymentMeta['description'] = 'Payment in taxi';
        this.ride.paymentMeta['method'] = 'unknown';
        this.ride.paymentMeta['moment'] = 'afterRide';
      }

      if (this.ride.PaymentBalance) {
        this.ride.openBalance = 0;
        this.ride.PaymentBalance.forEach((f) => {
          this.ride.openBalance = this.ride.openBalance + f.amount.total;
        });
      }
      if (this.ride.Payments) {
        this.ride.payment = this.ride.Payments[this.ride.Payments.length - 1];
      }

      if (this.ride.priceMethod === 'hourlyMeter' || this.ride.priceMethod === 'hourlyFixed') {
        this.form.controls['isHourlyBooking'].setValue(true);
        if (this.ride.priceMethod === 'hourlyMeter') {
          this.ride.priceMethod = 'onMeter';
        } else {
          this.ride.priceMethod = 'fixed';
        }
      }

      Object.keys(this.ride).forEach((k) => {
        if (this.form.controls[k] && this.ride[k]) {
          this.form.controls[k].patchValue(this.ride[k], {onlySelf: true});
        }
      });
      this.form.controls['product'].setValue(this.ride.products.filter((p) => {
        return (p.category === 'vehicleType');
      })[0]);

      setTimeout(() => {
        self._loadingService.resolve('ride.details');
        self._loadingService.resolve('ride.map');
        self._loadingService.resolve('ride.ratings');
        setTimeout(() => {
          self.loadDataDone = true;
        }, 400);
      }, 100);
    }, error => {
      console.error(error);
      setTimeout(() => {
        self._loadingService.resolve('ride.details');
        self._loadingService.resolve('ride.map');
        self._loadingService.resolve('ride.ratings');
      }, 500);
    })
  }

  saveJob(): void {
    const self = this;

    if (this.currentAction === 'save') {
      return;
    }
    this.currentAction = 'save';

    if (this.form.controls['passengerId'].value) {
      // @ts-ignore
      this.form.controls['passenger'].controls['email'].enable();
      // @ts-ignore
      this.form.controls['passenger'].controls['lname'].enable();
      // @ts-ignore
      this.form.controls['passenger'].controls['fname'].enable();
      // @ts-ignore
      this.form.controls['passenger'].controls['phoneNumber'].enable();
    }

    const data = JSON.parse(JSON.stringify(this.form.value));

    if (data.paymentMeta && data.paymentMeta.origin === 'creditcardOnline') {
      if (data.price && data.price.total > 0 && data.paymentMeta.paymentMoment === 'pickuptime') {
        data.paymentMeta.plannedPaymentMoment = moment(data.requestedDate).subtract(this.form.controls['paymentMeta']['controls']['hoursBeforePickup'].value, 'hours');

        if (moment(data.paymentMeta.plannedPaymentMoment).isBefore(moment())) {
          data.paymentMeta.plannedPaymentMoment = new Date().setSeconds(0, 0);
          data.paymentMeta.plannedPaymentMoment = moment(data.paymentMeta.plannedPaymentMoment).toISOString();
        }
      } else if (data.paymentMeta.paymentMoment === 'now') {
        data.paymentMeta.plannedPaymentMoment = new Date().setSeconds(0, 0);
        data.paymentMeta.plannedPaymentMoment = moment(data.paymentMeta.plannedPaymentMoment).toISOString();
      } else {
        delete data.paymentMeta.hoursBeforePickup;
        delete data.paymentMeta.paymentMoment;
      }
    }

    this._loadingService.register();
    this.formErrors = [];
    this.validateAllFormFields(this.form);

    let errorMsg = '';
    if (data['paymentMeta']['origin'] === 'creditcardOnline' && (!data['paymentMeta']['paymentMethodId'] || data['paymentMeta']['paymentMethodId'].toLowerCase() === 'select')) {
      // @ts-ignore
      this.form.controls['paymentMeta'].controls['paymentMethodId'].setErrors({'incorrect': true});
      errorMsg = this.translations['paymentmethodid_no_option_selected'] + ' \n';
    }

    if (this.quotationError && data.priceMethod === 'onMeter' && data.calculationMethod === 'group_pricing_rules') {
      this.form.controls['calculationMethod'].setErrors({'incorrect': true});
      errorMsg = this.translations['paymentmethod_group_pricing_rules_error'] + ' \n';
    }

    // stop here if form is invalid
    if (this.form.invalid) {
      Object.keys(this.formErrors).forEach((key) => {
        if (this.formErrors[key]) {
          errorMsg += this.formErrors[key] + ' \n';
        }
      });
      if (errorMsg !== '') {
        self._loadingService.resolve();
        this.currentAction = 'done';
        self.snackBar.open(errorMsg, self.translations['ok'], {duration: 30000});
        return;
      }
    }

    if (data.passenger && data.passenger.phoneNumber && data.passenger.phoneNumber.e164Number) {
      data.passenger.phoneNumber = data.passenger.phoneNumber.e164Number;
    }
    if (!data['passengerIsDebtor']) {
      data.debtorId = null;
    } else {
      if (this.form.controls['debtorId'].value && this.form.controls['debtorId'].value.indexOf('debtor') > -1) {
        data.debtorId = data.debtorId.split('-')[1];
      } else {
        data.debtorId = null;
      }
    }

    // if (data.passenger && data.passenger.language !== data.language) {
    //   data.language = data.passenger.language;
    // }

    delete data['passengerIsDebtor'];

    if (data.priceMethod === 'hourlyMeter' || data.priceMethod === 'hourlyFixed') {
      if (data.priceMeta.bookedDuration) {
        const split = data.priceMeta.bookedDuration.split(':');
        data.priceMeta.bookedDuration = (split[0] * 60 * 60) + (split[1] * 60);
      }

      if (data.priceMethod === 'hourlyFixed') {
        data.priceMeta.hourlyRate = 0;
      } else {
        data.priceMeta.hourlyRate = (data.priceMeta.hourlyRate * 100);
      }
    } else {
      data.priceMeta = null;
    }

    if (data.isHourlyBooking) {
      if (data.priceMethod === 'onMeter') {
        data.priceMethod = 'hourlyMeter';
      } else {
        data.priceMethod = 'hourlyFixed';
      }
    }

    if (data.priceMethod === 'onMeter' || data.priceMethod === 'hourlyMeter') {
      data.price = new Price();
      data.price.currency = this.currency;
    } else if (data.price.total) {
      data.price.total = (data.price.total * 100);
      data.price.breakdown = new PriceBreakdown();
      data.price.breakdown.route.total = data.price.total;
    }

    if (data.paymentMeta && data.paymentMeta.origin === 'onAccount') {
      data.paymentMeta.description = 'Pay by invoice';
      data.paymentMeta.method = 'invoice';
      data.paymentMeta.moment = 'afterRide';
    } else if (data.paymentMeta && data.paymentMeta.origin === 'creditcardOnline') {
      data.paymentMeta.description = 'Processing through PSP';
      data.paymentMeta.method = 'stripe';
      data.paymentMeta.moment = 'afterRide';
    }

    if (data.product) {
      if (data.product.amount && !data.product.price) {
        data.product.price = data.product.amount;
      }

      if (!data.products) {
        data.products = [];
      }

      if (data.product) {
        data.products.push({
          description: (data.product.name ? data.product.name : data.product.description),
          sku: data.product.sku,
          category: 'vehicleType',
          dispatchTypes: data.product.dispatchTypes,
          amount: {
            total: (data.product.price ? data.product.price.total : ''),
            currency: (data.product.price ? data.product.price.currency : this.currency),
            taxPercentage: (data.product.price ?
              (data.product.price.breakdown && data.product.price.breakdown.route ?
                data.product.price.breakdown.route.taxPercentage : data.product.price.taxPercentage) : 0)
          },
          imageUrl: (data.product.imagePath ? data.product.imagePath : data.product.imageUrl)
        });
        delete data['product'];
      }
    }

    data.requestedDate = moment(this.form.controls['requestedDate'].value).toISOString();

    this.newRide = new Ride();
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key]) {
        if ((key === 'departure' || key === 'destination') && data[key].city) {
          this.newRide[key] = data[key];
        } else if (key === 'passenger' && (data[key].email || data[key].phoneNumber || data[key].fname || data[key].lname)) {
          this.newRide[key] = data[key];
        } else if (key !== 'departure' && key !== 'destination' && key !== 'passenger') {
          this.newRide[key] = data[key];
        } else {
          delete this.newRide[key];
        }
      } else {
        delete this.newRide[key];
      }
    }

    this.newRide['flightNumber'] = data['flightNumber'];
    this.newRide['note'] = data['note'];

    if (!this.newRide['debtorId']) {
      this.newRide.debtorId = null;
    }
    /**
     * Call the API
     */
    if (this.action.toLowerCase() === 'add') {
      this.rideService.insert(this.newRide, 'company').subscribe((job) => {
        if (this.form.controls['passengerId'].value) {
          // @ts-ignore
          this.form.controls['passenger'].controls['email'].disable();
          // @ts-ignore
          this.form.controls['passenger'].controls['lname'].disable();
          // @ts-ignore
          this.form.controls['passenger'].controls['fname'].disable();
          // @ts-ignore
          this.form.controls['passenger'].controls['phoneNumber'].disable();
        }


        if (self.ride.Driver) {
          self.offerJobToDriver(job, self.ride.Driver);
        }
        setTimeout(function () {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['job_added_message']),
            disableClose: true,
            title: ucFirst(self.translations['job_added_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
            self.currentAction = 'done';
            if (self.context === 'company') {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/dispatchpanel`]);
            } else {
              self._router.navigate([`/personal/dispatchpanel`]);
            }
            self._loadingService.resolve();
          });
        }, 500);
      }, error => {
        console.error(error);
        self._loadingService.resolve();
        self._loadingService.resolve(this.loaderName);

        self.snackBar.open(ucFirst(self.translations['save_job_error']), self.translations['ok'], {
          duration: 60000
        });
        self.currentAction = 'done';
      })
    } else {
      this.rideService.update(this.rideId, this.newRide, 'company').subscribe((job) => {
        if (self.ride.Driver && job.status === 'unassigned') {
          self.offerJobToDriver(job, self.ride.Driver);
        }
        self.currentAction = 'done';
        setTimeout(function () {
          self.snackBar.open(ucFirst(self.translations['saved']), 'OK', {
            duration: 250
          });
          setTimeout(function () {
            if (self.context === 'company') {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/dispatchpanel`]);
            } else {
              self._router.navigate([`/personal/dispatchpanel`]);
            }
            self._loadingService.resolve();
          }, 250);
        })
      }, error => {
        console.error(error);
        self._loadingService.resolve();
        self._loadingService.resolve(this.loaderName);
      })
    }
  }

  offerJobToDriver(job, newDriver): void {
    const self = this;
    const offer: any = {
      productId: job.id,
      productType: 'ride',
      driverId: newDriver.id,
      preAssigned: true,
      status: 'success',
      type: 'driver'
    };
    if (this.context === 'company') {
      offer.companyId = this.company.id;
    }

    self.offerService.insert(offer, this.context).subscribe(() => {
      self.driverService.get(this.driverId, {}).subscribe((driver: Driver) => {
        self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
          duration: 3000
        });
      });
    });
  }

  cancelJob(): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_job_cancel_message'],
      disableClose: false,
      title: self.translations['confirm_job_cancel_title'],
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this.rideService.cancelJob(this.rideId, {'status': 'canceled'}, 'company').subscribe(() => {
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['job_canceled']), 'OK', {
              duration: 3000
            });
            setTimeout(function () {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/jobs`]);
            }, 1000);
          }, 500);
        }, error => {
          console.error(error);
          self._loadingService.resolve(this.loaderName);
        })
      }
    });
  }

  saveOperatorRating(): void {
    this._loadingService.register('ride.operator.rating');
    const self = this;

    if (this.hasOperatorRating) {
      this.ratingService.update(this.operatorRating.id, this.operatorRating, 'company').subscribe((operatorRating: Rating) => {
        this.operatorRating = operatorRating;
        setTimeout(() => {
          self._loadingService.resolve('ride.operator.rating');
          self.snackBar.open(self.operatorRatingSaved, 'OK', {
            duration: 2000,
          });
        }, 500);
      }, error => {
        console.error(error);
        setTimeout(() => {
          self._loadingService.resolve('ride.operator.rating');
        }, 500);
      });
    } else {
      const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
      this.driverService.getAll({
        where: {
          phoneNumber: encodeURIComponent(driver.phoneNumber),
          companyId: this.ride.companyId
        }
      }).subscribe((drivers: Driver[]) => {
        if (drivers[0]) {
          this.operatorRating.type = 'operator';
          this.operatorRating.rideId = this.ride.id;
          this.operatorRating.driverId = this.ride.driverId;
          this.operatorRating.ownerId = this.ride.companyId;
          this.operatorRating.ownerType = 'Company';
          this.operatorRating.creatorId = drivers[0].id;
          this.operatorRating.creatorType = 'Driver';

          console.log('OPERATOR RATING', this.operatorRating);

          this.ratingService.create(this.ride.id, this.operatorRating, 'company').subscribe((operatorRating: Rating) => {
            this.operatorRating = operatorRating;
            setTimeout(() => {
              self._loadingService.resolve('ride.operator.rating');
              self.snackBar.open(self.operatorRatingSaved, 'OK', {
                duration: 2000,
              });
            }, 500);
          }, error => {
            console.error(error);
            setTimeout(() => {
              self._loadingService.resolve('ride.operator.rating');
            }, 500);
          });
        } else {
          alert('Group driver could not be found!');
        }
      });
    }
  }

  cleanup(ride): any {
    delete ride.price.totalFormat;

    if (ride.price && !ride.price.currency) {
      ride.price.currency = this.currency;
    }

    if (ride.departure && !ride.departure.placeId) {
      ride.departure.placeId = '';
    }
    if (ride.destination) {
      delete ride.destination.driverId;
      delete ride.destination.sort;
      delete ride.destination.id;
      delete ride.destination.gpsOrigin;
      delete ride.destination.placesId;
      delete ride.destination.gpsAccuracy;
      delete ride.destination.gpsBearing;
      delete ride.destination.description;
      delete ride.destination.gpsSpeed;
      delete ride.destination.addressLine;

      if (!ride.destination.placeId) {
        ride.destination.placeId = '';
      }
      if (!ride.destination.type) {
        ride.destination.type = '';
      }
      if (!ride.destination.countryCode) {
        ride.destination.countryCode = '';
      }
      if (!ride.destination.streetName) {
        ride.destination.streetName = '';
      }
      if (!ride.destination.houseNumber) {
        ride.destination.houseNumber = '';
      }
      if (!ride.destination.postalCode) {
        ride.destination.postalCode = '';
      }
      if (!ride.destination.synonym) {
        ride.destination.synonym = '';
      }
      if (!ride.destination.code) {
        ride.destination.code = '';
      }
      if (!ride.destination.city) {
        ride.destination.city = '';
      }
      if (!ride.destination.internationalAlias) {
        ride.destination.internationalAlias = '';
      }
    }

    if (ride.departure) {
      delete ride.departure.driverId;
      delete ride.departure.sort;
      delete ride.departure.id;
      delete ride.departure.placesId;
      delete ride.departure.gpsOrigin;
      delete ride.departure.gpsAccuracy;
      delete ride.departure.gpsBearing;
      delete ride.departure.description;
      delete ride.departure.gpsSpeed;
      delete ride.departure.addressLine;

      if (!ride.departure.placeId) {
        ride.departure.placeId = '';
      }
      if (!ride.departure.type) {
        ride.departure.type = '';
      }
      if (!ride.departure.houseNumber) {
        ride.departure.houseNumber = '';
      }
      if (!ride.departure.postalCode) {
        ride.departure.postalCode = '';
      }
      if (!ride.departure.streetName) {
        ride.departure.streetName = '';
      }
      if (!ride.departure.synonym) {
        ride.departure.synonym = '';
      }
      if (!ride.departure.countryCode) {
        ride.departure.countryCode = '';
      }
      if (!ride.departure.internationalAlias) {
        ride.departure.internationalAlias = '';
      }
      if (!ride.departure.code) {
        ride.departure.code = '';
      }
      if (!ride.departure.city) {
        ride.departure.city = '';
      }
    }

    if (!ride.paymentMeta) {
      ride.paymentMeta = {
        origin: 'inTaxi'
      };
    }

    if (ride.paymentMeta.origin && !ride.paymentMeta.description) {
      ride.paymentMeta.description = 'Payment in taxi';
      ride.paymentMeta.method = 'unknown';
      ride.paymentMeta.moment = 'afterRide';
    }

    if (ride.passenger && !ride.passenger.language) {
      ride.passenger.language = 'en';
    }

    return ride;
  }
}
