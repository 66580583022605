import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatGridListModule} from '@angular/material/grid-list';
import {Threshold} from 'app/models/rule';
import {TableComponent} from '../table.component';
import {getCurrencySymbol} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

type RuleType = 'dynamic' | 'fixed' | 'hourly';
type ThresholdType = 'duration' | 'distance';

interface ThresholdEvent {
  type: RuleType;
  action: 'add' | 'remove';
  threshold: number;
}

const capitalize = (s) =>
  String(s).charAt(0).toUpperCase() + String(s).slice(1);

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class ColumnComponent extends MatGridListModule implements OnInit {
  // but instead show column names
  @Input() first = false;
  // If it's the first column, don't display values
  @Input() price: UntypedFormGroup;
  @Input() colIndex: number;
  @Input() form: UntypedFormGroup;
  @Input() ruleType: RuleType;
  @Input() origin: string;
  @Input() parent: TableComponent;
  public productName;
  public productImage;
  public backupImage;
  currencySymbol: string;
  private company;

  constructor(
    private _fb: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {
    super();
    const {company} = this.route.parent.snapshot.data;
    this.company = company;
  }

  childRules = () => this.form && this.form.controls.childRules
    ? this.form.controls.childRules['controls']
    : [];
  isDynamic = () => 'dynamic' === this.ruleType;
  isHourly = () => 'hourly' === this.ruleType;
  isFixed = () => 'fixed' === this.ruleType;

  /**
   * If input element value is not set, make it zero.
   */
  valOrZero(input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
    if (this.price.controls[input.getAttribute('formControlName')]) {
      this.price.controls[input.getAttribute('formControlName')].setValue(input.value);
    } else if (this.form.controls[input.getAttribute('formControlName')]) {
      this.form.controls[input.getAttribute('formControlName')].setValue(input.value);
    }
  }

  /**
   * Thresholds of rule type.
   */
  thresholds = (ruleType: RuleType) => {
    if (!this.price.controls['price' + capitalize(ruleType)] || !this.price.controls['price' + capitalize(ruleType)]['controls']) {
      return [];
    }
    return this.price.controls['price' + capitalize(ruleType)]['controls'].thresholds.controls;
  }

  /**
   * Update image url.
   */
  updateUrl(event: Event) {
    this.productImage = this.backupImage;
  }

  /**
   * Add threshold to rule.
   */
  addThreshold(thresholdType: ThresholdType) {
    this.parent.addThreshold(thresholdType);
  }

  /**
   * Update threshold at index.
   */
  updateThreshold(index: number, event: any) {
    this.parent.updateThreshold(index, event);
  }

  /**
   * Remove thresholds by criteria.
   */
  removeThreshold(old: Threshold, thresholdType: ThresholdType) {
    this.parent.removeThreshold(old, thresholdType);
  }

  /**
   * On component init.
   */
  ngOnInit() {
    this.productImage = this.price.value.product.imagePath;
    this.productName = this.price.value.product.name;
    this.backupImage = '../../../assets/mordor/placeholder.png';

    const {company} = (this.route.parent.snapshot.data.company ? this.route.parent.snapshot.data : this.route.snapshot.data);
    this.currencySymbol = getCurrencySymbol(company.currency, 'narrow');
  }

  /**
   * Add new child rule.
   */
  addChildRule(copyRule?: any, retour?: boolean) {
    this.parent.addChildRule(copyRule, retour);
  }

  /**
   * Add new child rule fixedPrice.
   */
  updateChildRulePriceFixed(id: string, fixedPrice: UntypedFormGroup, price: number) {
    console.log(id);
    console.log(fixedPrice);
    console.log(price);
    this.parent.updateChildRulePriceFixed(id, fixedPrice, price);
  }

  /**
   * Remove child rule by id.
   */
  removeChildRule(rule: any, index: number) {
    if (rule.value._id) {
      this.parent.removeChildRuleOnSave(rule.value._id);
    }
    rule.parent.removeAt(index);
    rule.parent.markAsDirty();
    console.log(rule);
  }
}
