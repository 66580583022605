<div [formGroup]="form" flex="100" class="push-top">
  <strong class="strong-header-margin">{{'message_center_conditions' | translate | ucFirst}}</strong>
  <div formArrayName="requirements" class="conditions">
    <div *ngFor="let c of conditionControls(); index as i" [formGroupName]="i" layout="row">
      <span>{{'when' | translate | ucFirst}}</span>
      <strong>{{c.controls['firstMatch'].value+'_condition'|translate}}:</strong>
      <div *ngIf="c.controls['secondMatch'].value">
        <span>{{'and' | translate}}</span>
        <strong class="strong-header-margin">{{'job_attribute_'+c.controls['secondMatch'].value.toLowerCase()|translate}}</strong>
        <span>{{'contains' | translate}}</span>
        <strong>{{c.controls['secondMatchValue'].value|translate}}</strong>
      </div>
      <span>{{c.controls['timeMatch'].value+'_timeset'|translate}}</span>
      <strong *ngIf="c.controls['timeValue'].value">
        {{c.controls['timeValue'].value}} {{'minutes'|translate}}
      </strong>
      <div *ngIf="c.controls['timeValue'].value">{{c.controls['timeMatch'].value+'_timeset_addition'|translate}}</div>

      <button (click)="updateCondition(i)"
              class="row-button button-dynamic"
              mat-icon-button
              matTooltip="{{ 'update_condition' | translate | ucFirst }}"
              matTooltipPosition="right">
        <mat-icon aria-label="{{'update_condition' |translate}}">
          edit
        </mat-icon>
      </button>
    </div>
  </div>
  <button (click)="addNewCondition()" color="accent" flex *ngIf="false"
          mat-raised-button>{{'add_condition'|translate}}</button>
</div>
