import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DispatchDialogComponent} from '../../../../shared/dispatchpanel/parts/dispatch-dialog/dispatch-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WebbookerIntegrationCodeComponent} from '../webbooker-integration-code/webbooker-integration-code.component';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-share-webbooker',
  templateUrl: './share-webbooker.component.html',
  styleUrls: ['./share-webbooker.component.scss']
})
export class ShareWebbookerComponent implements OnInit {
  @Input() webbookerUrl: string;
  @Input() webbookerId: string;
  @Input() webbooker: any;
  @Input() page: string;
  @Input() showIntegrationButton: string = 'true';
  translations: string[];

  constructor(
    private clipboard: Clipboard,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _dialogRef: MatDialogRef<DispatchDialogComponent>,
    public _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.webbookerUrl) {
      this.webbookerUrl = data.webbookerUrl;
    }
    if (data.webbookerId) {
      this.webbookerUrl = data.webbookerId;
    }
    _translateService.get([
      'link_copied',
      'ok'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.webbookerUrl);
    this._snackBar.open(ucFirst(this.translations['link_copied']), this.translations['code_copied'], {
      duration: 3000
    });
    this._dialogRef.close();
  }


  openWebbookerIntegrationCode(webbookerId, url, webbooker): void {
    const self = this;
    self._dialogService.open(WebbookerIntegrationCodeComponent, {
      maxHeight: '90%',
      height: 'auto',
      maxWidth: (window.innerWidth < 600 ? '100%' : '800px'),
      data: {
        parent: this,
        webbookerUrl: url,
        webbooker: {
          form: webbooker
        },
        webbookerId: webbookerId
      },
    });
  }
}
