import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import moment from 'moment-timezone';
import {Company} from '../models/company';

@Injectable()
export class UtilityService {

  constructor(private _vault: CoolLocalStorage) {
  }

  static notNullOrUndefined(value: any) {
    return (typeof value !== 'undefined' && value !== null && value !== 'null');
  }

  static getLocationString(location: any) {
    if (!location) {
      return '';
    }
    if (location.streetName && location.houseNumber && location.postalCode && location.city) {
      return location.streetName + ' ' + location.houseNumber + ', ' + location.postalCode + ' ' + location.city;
    } else if (location.streetName && location.houseNumber && location.city) {
      return location.streetName + ' ' + location.houseNumber + ', ' + location.city;
    } else if (location.internationalAlias) {
      return location.internationalAlias;
    } else if (location.streetName && location.city) {
      return `${location.streetName}, ${(location.postalCode ? `${location.postalCode} ` : '')}${location.city}`;
    } else if (location.gps) {
      return `<a href="https://maps.google.com/?q=${location.gps.lat},${location.gps.lng}" target="_new" class="yda-link-blue">${location.gps.lat},${location.gps.lng}</a>`;
    } else {
      return location;
    }
  }

  static tableDateSort(data: any, key: string, order: string) {
    const sortedData: any[] = data;
    sortedData.sort(function (a, b) {
      const dateA: any = new Date(a[key]).getTime();
      const dataB: any = new Date(b[key]).getTime();
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (order === 'ASC' ? dateA - dataB : dataB - dateA);
    });
    return sortedData;
  }

  static setBrowserTimeStamp(company: Company) {
    if (company) {
      // console.log('CurrentTimeZone', company.timezone);
      moment.tz.setDefault(company.timezone);
    }
  }

  static getJobTime(company: Company, datetime: Date): Date {
    if (company && company.timezone) {
      return moment.tz(datetime, company.timezone).toDate();
    } else {
      return moment(datetime).toDate();
    }
  }

  restrictCompanyFilter(filter: any, removeDriverId?: boolean): string {
    if (!filter) {
      filter = {};
    }
    if (!removeDriverId) {
      removeDriverId = false;
    }
    if (environment.companyBuild) {
      if (typeof filter.where !== 'undefined') {
        delete filter.where.companyId;
        delete filter.where.daAppInstallId;
        filter.where.or = [
          {daAppInstallId: environment.driverAppInstallationId},
          {companyId: environment.companyId},
          {ownerId: environment.companyId, ownerType: 'Company'},
        ];
      } else {
        filter.where = {
          or: [
            {daAppInstallId: environment.driverAppInstallationId},
            {companyId: environment.companyId},
            {ownerId: environment.companyId, ownerType: 'Company'}
          ]
        };
      }
      if (!removeDriverId) {
        filter.where.driverId = this._vault.getItem(`${environment.vaultPrefix}.companyDriverId`);
      }
      return filter;
    } else {
      return filter;
    }
  }

}
