import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DispatchDialogComponent} from '../../../../../shared/dispatchpanel/parts/dispatch-dialog/dispatch-dialog.component';
import {PromotionService} from '../../../../../../services/promotion.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {NavigationService} from '../../../../../../services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {SearchCountryField} from 'ngx-intl-tel-input';
import {ValidateAllFormFields} from '../../../../../../functions/validateAllFormFields';
import {UtilityService} from '../../../../../../services/utility.service';
import {Company} from '../../../../../../models/company';

/**
 * This component represents the promotion push dialog.
 */
@Component({
  selector: 'app-promotion-push-dialog',
  templateUrl: './promotion-push-dialog.component.html',
  styleUrls: ['./promotion-push-dialog.component.scss']
})
export class PromotionPushDialogComponent extends ValidateAllFormFields implements OnInit {
  // Fields
  company: Company;
  parentData: any;
  translations: string[];
  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
value
  constructor(public snackBar: MatSnackBar,
              private _translateService: TranslateService,
              private _formBuilder: UntypedFormBuilder,
              private _promotionService: PromotionService,
              private _route: ActivatedRoute,
              private _navigationService: NavigationService,
              private _dialogRef: MatDialogRef<DispatchDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    // Get translations
    _translateService.get([
      'push_message_send',
      'NO_API_KEY_FOUND_IN_INSTALL',
      'driver', 'ok',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });

    this.company = data.parent.company;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company.country = (this.company.country === 'EN' ? 'GB' : this.company.country);

    // Assign parent data
    this.parentData = data.parent;
    this.data = this.parentData.data;

    // Create form
    this.form = this._formBuilder.group({
      title: [this.data.title, [Validators.required]],
      text: [this.data.text, [Validators.required]],
      sendAll: ['phoneNumber', []],
      phoneNumber: ['', []],
    }, {validators: []});
  }

  // Methods

  /**
   * Lifecycle hook that is called after data-bound properties have been initialized.
   */
  ngOnInit(): void {
  }

  /**
   * Closes the notification dialog.
   */
  closeNotification(): void {
    this._dialogRef.close();
  }

  setPhoneValidators(): void {
    /**
     * Toggle form controls phoneNumber required if sendAll is phoneNumber
     */
    if (this.form.controls['sendAll'].value === 'phoneNumber') {
      this.form.controls['phoneNumber'].setValidators([Validators.required]);
    } else {
      this.form.controls['phoneNumber'].setValidators([]);
    }
  }

  /**
   * Sends the notification.
   */
  sendNotification(): void {
    const self = this;
    const data = this.form.value;


    if (data.phoneNumber && data.phoneNumber.e164Number) {
      // this.form.controls['phoneNumber'].setValue(data.phoneNumber.e164Number);
      data.phoneNumber = data.phoneNumber.e164Number;
    }

    this._promotionService.sendPushNotification(self.parentData.id, {
      title: data.title,
      text: data.text,
      receiver: (data.sendAll === 'all' ?  null : data.phoneNumber),
    }).subscribe((result) => {
      self.snackBar.open(ucFirst(self.translations[`push_message_send`].replace('{x}', result.pushCount)), self.translations['ok'], {
        duration: 10000
      });
    }, error => {
      console.log(`error_${error.error.name}`);
      self.snackBar.open(ucFirst(self.translations[`error_${error.error.name}`]), self.translations['ok'], {
        duration: 10000
      });
    })

    self._dialogRef.close();
  }

  protected readonly SearchCountryField = SearchCountryField;
}
