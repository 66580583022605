import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class NavigationService implements Resolve<string> {
  private mainMenuItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private companyMenuItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private subMenuItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private splitLayoutButtons: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private subMenuTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private helpTextTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private helpText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private bodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private actionLink: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private actionFn: BehaviorSubject<string> = new BehaviorSubject<any>('');
  private infoAction: BehaviorSubject<string> = new BehaviorSubject<any>('');
  private mainMenuSub: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private items: any[] = [];
  private subItems: any[] = [];
  private companyItems: any[] = [];
  private buttons: any[] = [];

  constructor() {
  }

  static sortMenuItems(items: any[]) {
    const tempItems = items;
    tempItems.sort(function (a, b) {
      return a.groupSort - b.groupSort;
    });
    for (let i = 0; i < tempItems.length; i++) {
      tempItems[i].routes.sort(function (a, b) {
        return a.sort - b.sort;
      });
    }
    return tempItems;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    const baseRoute = route.routeConfig.path.split('/').filter(function (e: any) {
      return e === 0 || e
    });
    const currentRoute = state.url.split('/').filter(function (e: any) {
      return e === 0 || e
    });
    const routeElements = [];

    baseRoute.forEach(function (value, index) {
      routeElements.push(currentRoute[index]);
    });

    const returnRoute = '/' + routeElements.join('/');
    this.subMenuItems.next(route.routeConfig.children);
    return returnRoute;
  }

  prepareMenuItems(routes: any[], menu: string) {
    /**
     * Set the variables we need to figure all this out
     */
    let currentGroup = '';
    let groupedRoutes: object = {
      name: '',
      routes: [],
      groupSort: 0
    };
    let menuItems: any[] = [];
    let groupRoutes: any[] = [];

    /**
     * Loop through our routes to find out which routes belong to which group
     */
    for (let i = 0; i < routes.length; i++) {
      /**
       * First element within the loop, force the name so it doesn't cause a split when i = 1
       */
      if (i === 0) {
        groupedRoutes = {...groupedRoutes, name: routes[i].group, groupSort: routes[i].groupSort};
        currentGroup = routes[i].group;
      }

      /**
       * Check if the group for this route is the same as our current
       * group and if so; add this group to the menu items and start a new group
       */
      if (currentGroup !== routes[i].group) {
        // Set the new current group
        currentGroup = routes[i].group;

        // Clear the groupRoutes
        groupRoutes = [];

        // Add the routes gathered from this group to our groupedRoutes object
        groupedRoutes = {...groupedRoutes, routes: groupRoutes};

        // Add our groupedRoutes object to the main menu
        menuItems = menuItems.concat(groupedRoutes);

        // Start a new groupedRoutes object
        groupedRoutes = {...groupedRoutes, name: currentGroup, routes: [], groupSort: routes[i].groupSort};
      } else {
        // Still the same group, push the current route into the group
        groupRoutes.push(routes[i]);
      }

      if (i >= routes.length - 1) {
        // Add the routes gathered from this group to our groupedRoutes object
        groupedRoutes = {...groupedRoutes, routes: groupRoutes};
        // Add our groupedRoutes object to the main menu
        menuItems = menuItems.concat(groupedRoutes);
      }
    }
    menuItems = NavigationService.sortMenuItems(menuItems);

    if (menu === 'main') {
      this.items = menuItems;
    } else {
      this.companyItems = menuItems;
    }
  }

  /**
   * Add new menu-items to our main menu
   */
  addMainMenuItems(routes: any[]) {
    this.prepareMenuItems(routes, 'main');
    this.mainMenuItems.next(this.items);
  }

  setSubMenuItems(routes) {
    this.subItems = routes;
    this.subMenuItems.next(this.subItems);
  }

  setCompanyMenuItems(routes: any[]) {
    this.prepareMenuItems(routes, 'company');
    this.companyMenuItems.next(this.companyItems);
  }

  setSplitLayoutButtons(buttons) {
    this.buttons = buttons;
    this.splitLayoutButtons.next(this.buttons);
  }

  setSubMenuTitle(title: string) {
    this.subMenuTitle.next(title);
  }

  setHelpTextTitle(title: string) {
    this.helpTextTitle.next(title);
  }

  setHelpText(title: string) {
    this.helpText.next(title);
  }

  setBodyTitle(title: string) {
    this.bodyTitle.next(title);
    this.helpText.next(null);
    this.helpTextTitle.next(null);
  }

  setActionLink(link: string) {
    if (link) {
      document.querySelector('body').classList.add('hasAction');
    } else {
      document.querySelector('body').classList.remove('hasAction');
    }

    this.actionLink.next(link);
  }

  setActionFn(fn: any) {
    if (fn) {
      document.querySelector('body').classList.add('hasAction');
    } else {
      document.querySelector('body').classList.remove('hasAction');
    }
    this.actionFn.next(fn);
  }

  setInfoAction(fn: any) {
    this.infoAction.next(fn);
  }

  setActiveSubmenu(submenu: string) {
    if (submenu) {
      this.mainMenuSub.next(submenu);
    } else {
      this.mainMenuSub.next('');
    }
  }

  getActiveSubmenu() {
    return this.mainMenuSub.asObservable();
  }

  getMainMenuItems(): Observable<any[]> {
    return this.mainMenuItems.asObservable();
  }

  getSubMenuItems(): Observable<any[]> {
    return this.subMenuItems.asObservable();
  }

  getCompanyMenuItems(): Observable<any[]> {
    return this.companyMenuItems.asObservable();
  }

  getSplitLayoutButtons(): Observable<any[]> {
    return this.splitLayoutButtons.asObservable();
  }

  getBodyTitle(): Observable<string> {
    return this.bodyTitle.asObservable();
  }

  getSubMenuTitle(): Observable<string> {
    return this.subMenuTitle.asObservable();
  }

  getHelpTextTitle(): Observable<string> {
    return this.helpTextTitle.asObservable();
  }

  getHelpText(): Observable<string> {
    return this.helpText.asObservable();
  }

  getActionLink(): Observable<string> {
    return this.actionLink.asObservable();
  }

  getActionFn(): Observable<string> {
    return this.actionFn.asObservable();
  }

  getInfoAction(): Observable<string> {
    return this.infoAction.asObservable();
  }
}
