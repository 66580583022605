<div [formGroup]="form" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom date-picker-pane" class="mobile-row"
     layout-gt-sm="row" tdMediaToggle="gt-xs">
  <div (click)="setRequestedDateTime()" *ngIf="this.requestedDateTime=='ASAP'"
       class="asap-overlay">{{'asap'|translate|ucFirst}}</div>
  <mat-list-item class="value" flex-gt-sm="50">
    <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input">
      <input [defaultValue]="(this.requestedDateTime ? this.requestedDateTime : '')"
             [value]="(this.requestedDateTime ? this.requestedDateTime : '')"
             formControlName="requestedDate"
             matInput
             placeholder="{{ 'pickuptime' | translate | ucFirst }}"
             type="datetime-local">
      <mat-hint align="start">
        <span [hidden]="!formErrors.requestedDate" class="tc-red-600">
          <span>{{ formErrors.requestedDate }}</span>
        </span>
      </mat-hint>
    </mat-form-field>
  </mat-list-item>

  <mat-list-item class="mobile-row" flex="100" flex-gt-sm="50">
    <div class="value" flex="100" flex-gt-sm="50">
      <mat-checkbox class="checkbox" flex-gt-sm="50" formControlName="isHourlyBooking" name="isHourlyBooking">
        {{ 'hourly_booking' | translate | ucFirst }}
      </mat-checkbox>
    </div>

    <mat-form-field *ngIf="this.form.controls['isHourlyBooking'].value" class="value" flex="100" flex-gt-sm="50"
                    formGroupName="priceMeta">
      <mat-label>{{'booked_duration'|translate|ucFirst}}</mat-label>
      <!-- The timepicker input -->
      <input (change)="bookedDurationChange()"
             defaultValue="00:00"
             formControlName="bookedDuration"
             id="timepicker-example"
             matInput
             mode="24h"
             name="time"
             strict="false"
             type="time">
    </mat-form-field>
  </mat-list-item>
</div>
