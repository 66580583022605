import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatAccordion} from '@angular/material/expansion';
import {Company} from '../../../../../models/company';
import {Router} from '@angular/router';
import {UpdateGoogleKeyDialogComponent} from '../../../cloud-connect/parts/dialog/update-google-key-dialog/update-google-key-dialog.component';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-webbooker-integration-code',
  templateUrl: './webbooker-integration-code.component.html',
  styleUrls: ['./webbooker-integration-code.component.scss'],
  animations: [
    trigger('slide', [
      state('left', style({transform: 'translateX(0)'})),
      state('right', style({transform: 'translateX(-50%)'})),
      transition('* => *', animate(300))
    ]),
    trigger('scrollNavigation', [
      state('open', style({transform: 'translateX(0)'})),
      state('closed', style({transform: 'translateX(-50%)'})),
      transition('* => *', animate(300))
    ])
  ]
})
export class WebbookerIntegrationCodeComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() webbookerUrl: string;
  @Input() webbookerId: string;
  @Input() showTitle = false;
  @Input() type = '';
  company: Company;
  hasGoogleApiKey = false;
  activePane = 'leftPane';
  activeOption = '';
  integrationInstructions = '';
  defaultSettings = {
    'topbar': true,
    'logo': true,
    'breadCrumbs': true,
    'navigation': 'inline',
    'language': 'en',
    'headercolor': '#000000',
    'textcolor': '#000000',
    'backgroundcolor': '#ffffff',
    'buttontextcolor': '#ffffff',
    'buttonbgcolor': '#000000',
    'buttonstyle': 'rounded',
    'buttonposition': 'bottomRight',
    'buttontext': 'Order now!',
    'font': 'Roboto, sans-serif',
  };
  settings = {};
  hasSetting = {
    'inline': {
      'topbar': true,
      'logo': true,
      'breadCrumbs': true,
      'navigation': 'inline',
      'language': 'en',
      'colors': [
        'headercolor',
        'textcolor',
        'backgroundcolor'
      ],
      'buttonstyle': 'rounded'
    },
    'iframe': {
      'topbar': true,
      'logo': true,
      'breadCrumbs': true,
      'language': 'en',
      'colors': [
        'headercolor',
        'textcolor',
        'backgroundcolor'
      ],
      'buttonstyle': 'rounded',
      'font': true
    },
    'floating-popup-widget': {
      'buttontext': true,
      'buttonstyle': true,
      'buttonposition': true,
      'topbar': true,
      'logo': true,
      'breadCrumbs': true,
      'language': 'en',
      'colors': [
        'headercolor',
        'textcolor',
        'backgroundcolor',
        'buttontextcolor',
        'buttonbgcolor',
      ],
    },
    'popup-text': {
      'topbar': true,
      'logo': true,
      'breadCrumbs': true,
      'language': 'en',
      'colors': [
        'headercolor',
        'textcolor',
        'backgroundcolor'
      ],
      'buttonstyle': 'rounded',
      'buttonPosition': 'bottomRight'
    },
  };
  navigationOptions = [
    'inline',
    'fixed',
    'popup'
  ]
  languageOptions = ['en', 'de', 'fr', 'es', 'it', 'nl', 'ar', 'ua', 'ja', 'zh'];
  buttonpositionOptions = ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'];
  buttonstyleOptions = [
    'rounded',
    'squared'
  ];
  fontStyleOptions = [
    'Roboto, sans-serif',
    'Trebuchet MS, Helvetica, Geneva, sans-serif',
    'Officina,Officina',
    'Arial,Arial,Helvetica,sans-serif',
    'Arial Black,Arial Black,Gadget,sans-serif',
    'Comic Sans MS,Comic Sans MS,cursive',
    'Courier New,Courier New,Courier,monospace',
    'Georgia,Georgia,serif',
    'Impact,Charcoal,sans-serif',
    'Lucida Console,Monaco,monospace',
    'Lucida Sans Unicode,Lucida Grande,sans-serif',
    'Palatino Linotype,Book Antiqua,Palatino,serif',
    'Tahoma,Geneva,sans-serif',
    'Times New Roman,Times,serif',
    'Verdana,Geneva,sans-serif',
    'Gill Sans,Geneva,sans-serif',
    'Satoshi, Satoshi'
  ];
  frontEndUrl: string;
  translations: string[];
  tag = 'inline-webbooker';
  path = 'inline-webbooker';
  navigation = 'tab';
  sections = {
    'booking': 'open',
    'widget': 'closed',
  };

  constructor(
    private clipboard: Clipboard,
    private _router: Router,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    public _dialogRef: MatDialogRef<WebbookerIntegrationCodeComponent>,
    public _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.parent && data.parent.company) {
      this.company = data.parent.company;
      this.hasGoogleApiKey = (this.company.googleKey && this.company.googleKey.active) ? true : false;
    }

    if (data.webbookerUrl) {
      this.webbookerUrl = data.webbookerUrl;
    }
    if (data.webbookerId) {
      this.webbookerId = data.webbookerId;
    }

    if (data.webbooker) {
      this.settings['headercolor'] = data.webbooker.form.config.styles.colors['global-title-color'];
    }

    this.frontEndUrl = environment.orderFormFrontEndUrl;

    _translateService.get([
      'code_copied',
      'ok'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });

    if (this.languageOptions[this.getLocalLang()]) {
      this.settings['language'] = this.languageOptions[this.getLocalLang()];
    }

    this.settings = JSON.parse(JSON.stringify(this.defaultSettings));
  }

  ngOnInit(): void {
  }

  getLocalLang(): string {
    if (navigator.languages) {
      const lang = navigator.language.split('-');
      return lang[0];
    }
    return 'en';
  }

  decodeHtml(html): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  copyToClipboard(id): void {
    this.clipboard.copy(this.decodeHtml(document.getElementById(`${id}-${this.webbookerId}`).innerHTML.replace(/  /g, ' ').replace(/  /g, ' ')));
    this._snackBar.open(ucFirst(this.translations['code_copied']), this.translations['code_copied'], {
      duration: 3000
    });
  }

  openIntegrationInstructions(type): void {
    if (!this.hasGoogleApiKey && (type !== 'iframe' && type !== '')) {
      return;
    }

    const self = this;
    if (type === '') {
      self.settings = JSON.parse(JSON.stringify(self.defaultSettings));
      self.type = type;
      self.activePane = 'left';
    } else {
      self.type = type;
      // self.activePane = 'right';
    }

    if (type === 'floating-popup-widget') {
      this.tag = 'popup-widget';
      this.path = 'popup-widget';
    } else if (type === 'popup-text') {
      this.tag = 'popup-widget';
      this.path = 'popup-widget';
    } else {
      this.tag = 'inline-webbooker';
      this.path = 'inline-webbooker';
    }
  }

  openGoogleKey(): void {
    this._dialogService.open(UpdateGoogleKeyDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  openManual() {
    this._dialogRef.close();
    this._router.navigate([`/groups/${this.company.id}/contract`]);
  }
}
