import {Address} from './address';
import {Shift} from './shift';
import {Price} from './price';
import {Passenger} from './passenger';
import {RideLog} from './ride-log';
import {Rating} from './rating';
import {Company} from './company';
import {PaymentMeta} from './payment-meta';
import {Driver} from './driver';
import {Vehicle} from './vehicle';
import {Coupon} from './coupon';
import {PriceMeta} from './price-meta';
// @ts-ignore
import {DriverTariff} from './driver-tariff';
import {Product} from './product';

export class PayoutCommissionField {
  total: number;
  currency: string;
  taxPercentage: number;
}

export class Ride {
  reference: string;
  intId: number;
  foreignDispatchId: number;
  foreignBookingId: number;
  passengerCount: number;
  debtorId: string;
  note?: string;
  flightNumber?: string;
  departure?: Address;
  destination?: Address;
  Passenger?: Passenger;
  passenger?: any;
  origin?: any;
  RideLog?: RideLog;
  Driver?: Driver;
  Dispatcher?: Driver;
  Vehicle?: Vehicle;
  Ratings?: Rating[];
  Shift?: Shift;
  pickupDate?: Date;
  requestedDate?: Date;
  timeToDispatch?: Date;
  requestedDateTime?: number;
  requestedDateTimeFormat?: string;
  status: string;
  language?: string;
  daAppInstallId?: string;
  vehicleType?: string;
  foreignId?: string;
  debtorCode?: string;
  price?: Price;
  products?: Product[];
  type?: string;
  id?: string;
  vehicleId?: string;
  driverId?: string;
  companyId?: string;
  userId?: string;
  passengerId?: string;
  shiftId?: string;
  paymentMeta?: PaymentMeta;
  PaymentBalance?: any[];
  openBalance?: any;
  Company: Company;
  Coupon: Coupon;
  LastJobOffer?: any;
  Offer?: any[];
  LastOffer?: any;
  priceMethod?: string;
  calculationMethod?: string;
  priceMeta?: PriceMeta;
  driverNote?: string;
  operatorNote?: string;
  commission?: any;
  Debtor?: any;
  Owner?: any;
  ownerType?: string;
  ownerId?: string;
  lastModifierType?: string;
  actionOrigin?: string;
  events?: any;
  commissionRules?: any[];
  metrics?: any;
  driverPrice?: PayoutCommissionField;
  driverSettlement?: PayoutCommissionField;
  created?: Date;
  modified?: Date;
  driverTariffId: string;
  DriverTariff: DriverTariff;
  payment: any;
  Payments: any;
  MessageLog: any;
  resources: any;
  cancellation: any;

  constructor() {
    this.departure = new Address();
    // this.destination = new Address();
    this.price = new Price();
    // this.Passenger = new Passenger();
    // this.Driver = new Driver();
    // this.Vehicle = new Vehicle();
    // this.Shift = new Shift();
    // this.RideLog = new RideLog();
    this.paymentMeta = new PaymentMeta();
    this.priceMeta = new PriceMeta();
    // this.priceMethod = '';
    // this.Ratings = [];
    // this.reference = '';
    this.lastModifierType = 'dispatcher';
    this.actionOrigin = 'DriverPortal';
  }
}
