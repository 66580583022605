import {Directive, DoCheck, Input} from '@angular/core';
import {Ride} from '../models/ride';
import {GoogleMapsAPIWrapper} from '@agm/core';
import {UntypedFormGroup} from '@angular/forms';

declare var google: any;

@Directive({
  selector: 'app-agm-custom-waypoints'
})

export class DirectionsMapWaypointsDirective implements DoCheck {
  @Input() ride: Ride;
  @Input() form: UntypedFormGroup;

  currentAddressArrayLength = 1;

  addressArrayHash = '';

  directionsService: any = undefined;
  directionsDisplay: any = undefined;

  constructor(private gmapsApi: GoogleMapsAPIWrapper) {
  }

  static generateLocationHash(array: any) {
    return JSON.stringify(array);
  }

  drawRoute() {
    const self = this;

    if (typeof (google) === 'undefined') {
      setTimeout(() => {
        this.drawRoute();
      }, 100);
      return false;
    }

    this.gmapsApi.getNativeMap().then(map => {
      if (!self.directionsService) {
        self.directionsService = new google.maps.DirectionsService;
      }
      if (!self.directionsDisplay) {
        self.directionsDisplay = new google.maps.DirectionsRenderer;
      }
      self.directionsDisplay.setMap(map);
      self.directionsDisplay.setOptions({suppressMarkers: true});
      self.directionsService.route({
        origin: {
          lat: self.ride.departure.gps.lat,
          lng: self.ride.departure.gps.lng
        },
        destination: {
          lat: self.ride.destination.gps.lat,
          lng: self.ride.destination.gps.lng
        },
        travelMode: 'DRIVING'
      }, function (response: any, status: any) {
        if (status === 'OK') {
          self.directionsDisplay.setDirections(response);
          self.ride.metrics = {
            distance: 0,
            duration: 0
          };
          const legs = response.routes[0].legs;
          for (let i = 0; i < legs.length; ++i) {
            self.ride.metrics.distance += legs[i].distance.value;
            self.ride.metrics.duration += legs[i].duration.value;
          }
        } else {
          console.log('Waypoint: Directions request failed due to ' + status);
        }
      });
    });
  }

  ngDoCheck(): void {
    const newAddressArrayHash = DirectionsMapWaypointsDirective.generateLocationHash(
      {departure: this.ride.departure, destination: this.ride.destination});
    if (
      this.addressArrayHash !== newAddressArrayHash &&
      (this.ride.departure && this.ride.departure.gps && this.ride.departure.gps.lat) &&
      (this.ride.destination && this.ride.destination.gps && this.ride.destination.gps.lat)) {
      this.addressArrayHash = newAddressArrayHash;
      console.log(`[DirectionsMapWaypoints.ngDoCheck]: Address array changed, drawing new route`);
      this.drawRoute();
    }
  }
}
