import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../../../../models/ride';
import {DispatchDialogComponent} from '../dispatch-dialog/dispatch-dialog.component';
import {RideEditAssignComponent} from '../ride-edit/parts/ride-edit-assign/ride-edit-assign.component';
import {Offer} from '../../../../../models/offer';
import {ShareOfferComponent} from '../ride-edit/parts/ride-edit-assign/parts/share-offer/share-offer.component';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {TdMediaService} from '@covalent/core/media';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExporterService} from '../../../../../services/exporter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDataTableService} from '@covalent/core/data-table';
import {RideService} from '../../../../../services/ride.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../services/navigation.service';
import {OfferService} from '../../../../../services/offer.service';
import {Title} from '@angular/platform-browser';
import {Company} from '../../../../../models/company';
import {DispatchpanelComponent} from '../../dispatchpanel.component';

@Component({
  selector: 'app-dispatchpanel-actions',
  templateUrl: './dispatchpanel-actions.component.html',
  styleUrls: ['./dispatchpanel-actions.component.scss']
})
export class DispatchpanelActionsComponent implements OnInit {
  @Input() jobDetails;
  @Input() context;
  @Input() item;
  @Input() activeTab;
  @Input() hasContract;
  @Input() companyId;
  @Input() company: Company;
  @Input() translations;
  @Input() parent: DispatchpanelComponent;
  departureGps: any;
  reference: string;
  rideId: string;

  constructor(public media: TdMediaService,
              public snackBar: MatSnackBar,
              private _exporter: ExporterService,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _dataTableService: TdDataTableService,
              private _rideService: RideService,
              private _dialogService: TdDialogService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _route: ActivatedRoute,
              private _offerService: OfferService,
              private _titleService: Title) {
  }

  ngOnInit(): void {
  }

  openEdit(id, route): void {
    if (this.context === 'company') {
      if (!route) {
        this._router.navigate([`/groups/${this.companyId}/dispatchpanel/${id}`], {queryParams: {return: this.activeTab}});
      }
      this._router.navigate([`/groups/${this.companyId}/dispatchpanel/${id}/${route}`], {queryParams: {return: this.activeTab}});
    } else {
      if (!route) {
        this._router.navigate([`/personal/dispatchpanel/${id}`], {queryParams: {return: this.activeTab}});
      }
      this._router.navigate([`/personal/dispatchpanel/${id}/${route}`], {queryParams: {return: this.activeTab}});
    }
  }

  openDispatchDialog(id) {
    const self = this;
    this._rideService.get(id, {}).subscribe((ride: Ride) => {
      self.departureGps = ride.departure.gps;
      self.reference = ride.reference;
      self.rideId = ride.id;
      self._dialogService.open(DispatchDialogComponent, {
        maxHeight: '80%',
        maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
        data: {
          parent: this,
        },
      });
    });
  }

  openQuickDispatchDialog(id, alert: any = '') {
    const self = this;
    if (alert !== '' && alert !== true) {
      this._dialogService.openConfirm({
        title: this.translations['dispatch_not_allowed'],
        message: this.translations[alert],
        disableClose: true,
        cancelButton: self.translations['no'],
        acceptButton: self.translations['yes'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.openQuickDispatchDialog(id);
        }
      });
    } else {
      this._rideService.get(id, {include: ['LastOffer']}).subscribe((ride: Ride) => {
        self.departureGps = ride.departure.gps;
        self.reference = ride.reference;
        self.rideId = ride.id;
        self._dialogService.open(RideEditAssignComponent, {
          maxHeight: '80%',
          panelClass: 'no-dialog-padding',
          maxWidth: (window.innerWidth < 600 ? '100%' : '600px'),
          data: {
            parent: this,
            ride: ride,
          },
        });
      });
    }
  }

  openDispatchLinkDialog(offer: Offer, ride: any) {
    this._dialogService.open(ShareOfferComponent, {
      maxHeight: '80%',
      width: '570px',
      data: {
        parent: this,
        ride: ride,
        shareLink: offer.deepLinkUrl
      }
    });
  }

  openShareDispatchDialog(rideId): void {
    let windowNavigator: any;
    windowNavigator = window.navigator

    const self = this;
    const offer: any = {
      productId: rideId,
      productType: 'ride',
      companyId: (this.company ? this.company.id : null),
      preAssigned: false,
      type: 'sharedToGroup',
      origin: {
        'type': 'portal'
      }
    };

    self._offerService.insert(offer, this.context).subscribe((o: Offer) => {
      self._rideService.get(rideId).subscribe((ride) => {
        self._dialogService.open(ShareOfferComponent, {
          maxHeight: '80%',
          width: '570px',
          data: {
            ride: ride,
            shareLink: o.deepLinkUrl
          }
        });
        self.parent.reloadAllData();
      });
    }, (error) => {
      self.parent.offerError(error, rideId);
    });
  }

  cancelJob(rideId): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_job_cancel'],
      disableClose: false,
      title: self.translations['confirm_job_cancel_title'],
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._rideService.cancelJob(rideId, {'status': 'canceled'}, this.context).subscribe(() => {
          self._loadingService.resolve(self.parent.loaderName);
          self.snackBar.open(ucFirst(self.translations['job_canceled']), self.translations['ok'], {
            duration: 3000
          });
          // self._loadingService.register('rides.list');
          self.parent.loadData();
        }, error => {
          console.error(error);
          self._loadingService.resolve(self.parent.loaderName);
        })
      }
    });
  }

  unassignDriver(rideId): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_unassign_driver'],
      disableClose: false,
      title: self.translations['confirm_unassign_driver_title'],
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._rideService.update(rideId, {'status': 'unassigned', 'driverId': null, 'vehicleId': null}, this.context).subscribe(() => {
          self._loadingService.resolve(self.parent.loaderName);
          self.snackBar.open(ucFirst(self.translations['job_canceled']), self.translations['ok'], {
            duration: 3000
          });
          self._loadingService.register('rides.list');
          self.parent.reloadAllData();
        }, error => {
          console.error(error);
          self._loadingService.resolve(self.parent.loaderName);
        })
      }
    });
  }

  open(id): void {
    if (this.context === 'company') {
      this._router.navigate([`/groups/${this.companyId}/dispatchpanel/${id}/details`], {queryParams: {return: this.activeTab}});
    } else {
      this._router.navigate([`/personal/dispatchpanel/${id}/details`], {queryParams: {return: this.activeTab}});
    }
  }
}
