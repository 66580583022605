<div flex="100" layout="row">
  <div flex="90" layout="column">
    <mat-list-item class="push-bottom second-title">
      <mat-icon *ngIf="this.active" [ngStyle]="{'color':'green'}" mat-list-icon>check_circle</mat-icon>
      <mat-icon *ngIf="!this.active" [ngStyle]="{'color':'red'}" mat-list-icon>error</mat-icon>
      <h3 mat-line>{{'cloud_connect_googlekey_title'|translate|ucFirst}}</h3>
    </mat-list-item>

    <div class="pull-top" flex="100" layout="row" layout-gt-sm="row">
      <mat-card-subtitle *ngIf="this.active" [innerHTML]="this.translations['cloud_connect_googlekey_active']+': '+this.googleClientSecret" flex="100" layout="column"
                         style="padding-left:25px;"></mat-card-subtitle>
      <mat-card-subtitle *ngIf="!this.active" [innerHTML]="this.translations['cloud_connect_googlekey_inactive']" flex="100" layout="column"
                         style="padding-left:25px;"></mat-card-subtitle>
    </div>
  </div>
  <div class="button-column" layout="column">
    <button (click)="this.openGoogleKey()" class="push-right push-top push-bottom edit-button"
            flex
            mat-raised-button>
      {{'cloud_connect_googlekey_submit'|translate|ucFirst}}</button>
  </div>
</div>
