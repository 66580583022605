import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address} from '../../../../../models/address';
import {UtilityService} from '../../../../../services/utility.service';

@Component({
  selector: 'app-ride-location-data',
  templateUrl: './ride-location-data.component.html',
  styleUrls: ['./ride-location-data.component.scss']
})
export class RideLocationDataComponent implements OnInit, OnChanges {

  @Input() departure: Address;
  @Input() actualDeparture: Address;
  @Input() destination: Address;
  @Input() actualDestination: Address;
  @Input() metrics: any = {};

  departureString: string;
  actualDepartureString: string;
  destinationString: string;
  actualDestinationString: string;
  expanded = false;

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.departureString = UtilityService.getLocationString(this.departure);
    this.actualDepartureString = UtilityService.getLocationString(this.actualDeparture);
    this.destinationString = UtilityService.getLocationString(this.destination);
    this.actualDestinationString = UtilityService.getLocationString(this.actualDestination);
  }

}
