import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {RideService} from '../../../../../services/ride.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../../environments/environment';
import {Ride} from '../../../../../models/ride';
import {GeoPoint} from '../../../../../models/geo-point';
import {NavigationService} from '../../../../../services/navigation.service';
import {Rating} from '../../../../../models/rating';
import {RatingService} from '../../../../../services/rating.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GpsLogEntry} from '../../../../../models/gps-log-entry';
import {Shift} from '../../../../../models/shift';
import {Driver} from '../../../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DriverService} from '../../../../../services/driver.service';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../../googleMapConfig';
import {TdDialogService} from '@covalent/core/dialogs';
import {RatingComponent} from './parts/rating/rating.component';
import {TimeZoneService} from '../../../../../services/timezone.service';
import {Company} from '../../../../../models/company';
import {PassengerService} from '../../../../../services/passenger.service';
import {PaymentOverviewComponent} from '../../payment-overview/payment-overview.component';
import moment from 'moment';

@Component({
  selector: 'app-ride-details',
  templateUrl: './ride-details.component.html',
  styleUrls: ['./ride-details.component.scss'],
  providers: [TimeZoneService, RideService, RatingService, PassengerService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}]
})
export class RideDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  companyId: string;
  company: Company;
  rideId: string;
  iconUrl: string;
  departureIconUrl: string;
  destinationIconUrl: string;
  mapLineColor: string;
  hasPassenger = false;
  hasPassengerRating = false;
  hasOperatorRating = false;
  mapInstance: any;
  mapCenter: GeoPoint = new GeoPoint();
  ride: Ride = new Ride();
  vehicleProduct: any;
  shift: Shift = new Shift();
  gpsLogEntries: GpsLogEntry[] = [];
  passengerRating: Rating = new Rating();
  operatorRating: Rating = new Rating();
  driverId: string;
  isOwner = true;
  isDriver = false;
  stopReloading = false;
  translations: string[] = [];
  protected readonly moment = moment;

  constructor(public media: TdMediaService,
              public snackBar: MatSnackBar,
              private loadingService: TdLoadingService,
              private activatedRoute: ActivatedRoute,
              private rideService: RideService,
              private driverService: DriverService,
              private ratingService: RatingService,
              private translateService: TranslateService,
              private _navigationService: NavigationService,
              private _passengerService: PassengerService,
              private vault: CoolLocalStorage,
              private titleService: Title,
              private _dialogService: TdDialogService) {
    const params: any = this.activatedRoute.snapshot.params;
    const parentParams: any = this.activatedRoute.parent.snapshot.params;
    this.rideId = params.id;

    this._navigationService.setActiveSubmenu(this.activatedRoute.routeConfig['submenu']);
    this.companyId = parentParams.id;
    const {company} = this.activatedRoute.parent.snapshot.data;
    this.company = company;

    this.iconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.departureIconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.destinationIconUrl = `/assets/${environment.buildName}/icon-destination-marker.png`;
    this.mapLineColor = `${environment.mapLineColor}`;

    const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    if (driver) {
      this.driverId = driver.id;
    }

    translateService.get(['job_details', 'edit', 'jobs', 'operator_rating_saved', 'save', 'saved', 'dispatch_log', 'payment_status_pickup_at'])
      .subscribe((translations: any) => {
        this.titleService.setTitle(translations['job_details'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['job_details']);
        this.translations = translations;

        const urlParts = this.getResolvedUrl(this.activatedRoute.snapshot).split('/');

        this._navigationService.setSplitLayoutButtons([
          {
            'icon': 'list',
            'tooltip': this.translations['jobs'],
            'link': `/groups/${this.companyId}/${(urlParts[3] === 'dispatchpanel' ? 'dispatchpanel' : 'jobs')}/`,
            'queryParams': {'return': this.activatedRoute.snapshot.queryParams['return']}
          },
          {
            'icon': 'edit',
            'tooltip': this.translations['edit'],
            'link': `/groups/${this.companyId}/${(urlParts[3] === 'dispatchpanel' ? 'dispatchpanel' : 'jobs')}/${this.rideId}/edit`,
            'queryParams': {'return': this.activatedRoute.snapshot.queryParams['return']}
          }
        ]);
      });

    this.loadData();
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

  ngOnInit() {
    this.loadingService.register('ride.details');
    this.loadingService.register('ride.details2');
    this.loadingService.register('ride.details.map');
    this.loadingService.register('ride.details.driver');
    this.loadingService.register('ride.details.driver2');
  }

  resolveAllLoaders() {
    this.loadingService.resolveAll('ride.details');
    this.loadingService.resolveAll('ride.details2');
    this.loadingService.resolveAll('ride.details.map');
    this.loadingService.resolveAll('ride.details.driver');
    this.loadingService.resolveAll('ride.details.driver2');
  }

  reloadDriverData() {
    this.loadingService.register('ride.details.driver');
    this.loadingService.register('ride.details.driver2');
    this.loadData();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
    this.stopReloading = true;
  }

  mapReady(map): void {
    this.mapInstance = map;
  }

  loadData(): void {
    const self = this;
    const query = {
      'include': [
        'Vehicle',
        'Driver',
        'Ratings',
        'Passenger',
        'LastJobOffer',
        'Offer',
        'Dispatcher',
        'Payments',
        'Debtor',
        'Coupon',
        'Owner',
        'MessageLog',
        'DriverTariff',
        {
          'relation': 'PaymentBalance',
          'scope': {
            'where': {
              'rideId': this.rideId
            },
            'order': 'created ASC'
          }
        },
        {
          'Shift': [
            {
              'relation': 'ShiftEntries',
              'scope': {
                'where': {
                  'rideId': this.rideId
                }
              }
            },
            {
              'relation': 'GpsLogEntries',
              'scope': {
                'where': {
                  'rideId': this.rideId,
                  'created': {'gt': 0}
                },
                'order': 'created ASC' ,
                'limit': 3600
              }
            }
          ]
        }
      ]
    };

    this.rideService.get(this.rideId, query).subscribe((ride: Ride) => {
        this.shift = {...ride.Shift};
        this.gpsLogEntries = (typeof ride.Shift !== 'undefined' ? {...ride.Shift.GpsLogEntries} : []);
        delete ride.Shift;
        ride.intId = (ride.foreignDispatchId ? ride.foreignDispatchId : (ride.foreignBookingId ? ride.foreignBookingId : ride.intId));
        this.ride = ride;

        this.vehicleProduct = (this.ride.products.filter((p) => {
          return (p.category === 'vehicleType');
        })[0]);

        if (ride.passenger) {
          this.hasPassenger = true;
        }
        this.isDriver = (this.ride.driverId === this.driverId);
        this.ride.Ratings.forEach(r => {
          if (r.type === 'operator') {
            this.operatorRating = r;
            this.hasOperatorRating = true;
          }
        });

        if (this.ride.Offer) {
          this.ride.LastOffer = this.ride.Offer[this.ride.Offer.length - 1];
          if (this.ride.LastOffer && !this.stopReloading) {
            if (!['success', 'expired', 'failed', 'canceled'].includes(this.ride.LastOffer.status)) {
              setTimeout(function () {
                self.loadData();
              }, 5000);
            }
          }
        }

        if (this.ride.paymentMeta && this.ride.paymentMeta.origin === 'creditcardOnline') {
          if (this.ride.Payments.length > 0) {
            this.ride.payment = this.ride.Payments[this.ride.Payments.length - 1];
          } else {
            this.ride.payment = null;
          }
          const details = PaymentOverviewComponent.showPaymentStatus(this.translations, this.company, this.ride.payment, this.ride);

          if (!this.ride.payment) {
            this.ride.payment = {};
          }
          this.ride.payment.paymentStatus = details.paymentStatus;
          this.ride.payment.paymentStatusColor = details.paymentStatusColor;
        }

        if (this.ride.passengerId) {
          this._passengerService.paymentMethods(this.ride.passengerId)
            .subscribe((result: any) => {
              this.ride.passenger.paymentMethod = result.filter((p) => {
                return (p.id === this.ride.paymentMeta.paymentMethodId)
              })[0];
            });
        }

        if (this.ride && this.ride.cancellation && this.ride.cancellation.cancellationChargeAmount) {
          this.ride.cancellation.cancellationChargeAmount = {
            total: this.ride.cancellation.cancellationChargeAmount,
            currency: this.company.currency
          };
        }

        if (!this.gpsLogEntries.length && this.shift.ShiftEntries && this.shift.ShiftEntries.length > 0) {
          const gpsQuery = {
            'where': {
              'created': {
                'between': [
                  this.shift.ShiftEntries[0].startTime,
                  this.shift.ShiftEntries[this.shift.ShiftEntries.length - 1].endTime
                ]
              }
            }
          };
          this.rideService.getGpsLog(gpsQuery).subscribe((gpsLogEntries: GpsLogEntry[]) => {
            self.gpsLogEntries = gpsLogEntries;
            setTimeout(() => {
              self.resolveAllLoaders();
            }, 500);
          });
        } else {
          setTimeout(() => {
            self.resolveAllLoaders();
          }, 500);
        }
      },
      error => {
        console.error(error);
        setTimeout(() => {
          self.resolveAllLoaders();
        }, 500);
      })
  }

  openRatingDialog() {
    this._dialogService.open(RatingComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {
        parent: this,
      },
    });
  }
}
