<mat-list-item>
  <mat-icon mat-list-icon>person</mat-icon>
  <h4 mat-line>{{'passenger'|translate|ucFirst}}</h4>
</mat-list-item>

<div [formGroup]="form" class="row-container">
  <div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm row-layout" layout-gt-sm="row"
       tdMediaToggle="gt-xs">
    <mat-list-item flex-gt-sm="50">
      <mat-form-field class="value form-left">
        <input [value]="passengerCount" autocomplete="off"
               formControlName="passengerCount"
               matInput
               max="1000"
               min="1"
               placeholder="{{ 'passenger_capacity' | translate | ucFirst }}"
               type="number">
      </mat-form-field>
      <mat-form-field class="value form-right">
        <mat-label>{{'language'|translate|ucFirst}}</mat-label>
        <mat-select class="row-select"
                    flex="100"
                    formControlName="language" id="language">
          <mat-option *ngFor="let language of languages" value="{{language}}">
            {{ 'language_' + language | translate | ucFirst}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
  </div>

  <div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm row-layout" formGroupName="passenger" layout-gt-sm="row"
       tdMediaToggle="gt-xs">
    <mat-list-item class="value" flex-gt-sm="50">
      <mat-form-field class="full-width">
        <input [matAutocomplete]="auto" [value]="( this.passenger ? this.passenger.fname: '')"
               autocomplete="passenger-fname"
               formControlName="fname"
               id="fname"
               matInput
               name="fname"
               placeholder="{{ 'firstname' | translate | ucFirst }}"
               type="text">
        <mat-autocomplete #auto="matAutocomplete" autocomplete="passenger-fname">
          <mat-option
            (onSelectionChange)="selectPassenger($event, res)"
            *ngFor="let res of contacts"
            [value]="res.fname">
            <span>{{res.fname}}{{(res.lname !== '-' ? ' ' + res.lname : '')}} - {{res.phoneNumber}}
              - {{res.email}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="value mobile-row" flex-gt-sm="50">
      <mat-form-field class="full-width">
        <input [matAutocomplete]="autolname" [value]="( this.passenger ? this.passenger.lname: '')"
               autocomplete="passenger-lname"
               formControlName="lname"
               id="lname"
               matInput
               name="lname"
               placeholder="{{ 'lastname' | translate | ucFirst }}"
               type="text">
        <mat-autocomplete #autolname="matAutocomplete" autocomplete="passenger-lname">
          <mat-option
            (onSelectionChange)="selectPassenger($event, res)"
            *ngFor="let res of contacts"
            [value]="res.fname +' '+res.lname">
            <span>{{res.fname}}{{(res.lname !== '-' ? ' ' + res.lname : '')}} - {{res.phoneNumber}} -
              {{res.email}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-list-item>
  </div>

  <div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm row-layout" formGroupName="passenger"
       layout-gt-sm="row"
       tdMediaToggle="gt-xs">
    <mat-list-item class="value" flex-gt-sm="50">
      <mat-form-field class="full-width">
        <input [matAutocomplete]="autoemail" [value]="( this.passenger ? this.passenger.email: '')"
               autocomplete="passenger-email"
               formControlName="email"
               id="email"
               matInput
               name="email"
               placeholder="{{ 'emailaddress' | translate | ucFirst }}"
               type="text">
        <mat-autocomplete #autoemail="matAutocomplete" autocomplete="passenger-email">
          <mat-option
            (onSelectionChange)="selectPassenger($event, res)"
            *ngFor="let res of contacts"
            [value]="res.fname +' '+res.lname +' '+res.email">
            <span>{{res.fname}}{{(res.lname !== '-' ? ' ' + res.lname : '')}} - {{res.email}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-list-item>

    <mat-list-item class="value" flex-gt-sm="50">
      <span class="phonenumner_placeholder">{{'phonenumber'|translate|ucFirst}}</span>
      <div class="phoneNumberField {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex
           flex="100" style="padding-top:7px;">
        <ngx-intl-tel-input
          (keyup)="passengerPhoneInput($event)"
          [cssClass]="'phoneNumberInput'"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [maxLength]="'15'"
          [phoneValidation]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [searchCountryFlag]="true"
          [selectFirstCountry]="false"
          [selectedCountryISO]="(this.company && this.company.country === 'EN'? 'GB' : this.country)"
          autocomplete="passenger-phone"
          flex="100"
          formControlName="phoneNumber"
          name="phoneNumber">
        </ngx-intl-tel-input>

        <mat-hint align="start" class="warning">
          <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
            <span>{{ formErrors.phoneNumber }}</span>
          </span>
        </mat-hint>
      </div>
    </mat-list-item>
  </div>


  <div [mediaClasses]="['push-sm']" class="layout-row" layout-gt-sm="row" tdMediaToggle="gt-xs">
    <div *ngIf="this.form.controls['passenger']['controls']['fname'].value &&
    this.form.controls['passenger']['controls']['lname'].value &&
    this.form.controls['passenger']['controls']['email'].value &&
    this.form.controls['passenger']['controls']['phoneNumber'].value &&
    this.form.controls['passenger']['controls']['email'].valid && !passenger && !ride.passengerId"
         [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
      <button (click)="confirmCreateContact()" class="edit-button" color="primary"
              mat-raised-button>{{'add_contact' | translate | ucFirst}}</button>
    </div>

    <div *ngIf="passenger || ride.passengerId" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
         tdMediaToggle="gt-xs">
      <button (click)="changeContact()" class="edit-button" color="primary"
              mat-raised-button>{{'change_contact' | translate | ucFirst}}</button>
    </div>

    <mat-list-item flex-gt-sm="50">
      <mat-checkbox (change)="conditionalFormatting.emit()" formControlName="passengerIsDebtor"
                    name="passenger_is_debtor">
        {{ 'passenger_is_debtor' | translate | ucFirst }}
      </mat-checkbox>
    </mat-list-item>

    <mat-list-item *ngIf="form.controls['passengerIsDebtor'].value" class="value" flex-gt-sm="50">
      <app-ride-edit-debtor [context]="context" [debtor]="debtor" [form]="form" flex="100"></app-ride-edit-debtor>
    </mat-list-item>
  </div>
</div>
