<div [@slide]="activePane" class="parent">
  <div class="integration-type-pane left-pane" select="[leftPane]">
    <mat-card-content class="integration-pane">
      <mat-card-title>{{'webbooker_integration_select_option_title'|translate|ucFirst}}</mat-card-title>
      <div class="integration-type">
        <div (click)="openIntegrationInstructions('inline')" class="integration-type-text {{(type==='inline' ? 'activeOption' : '')}}"
             layout="row">
          <img src="../../../../../../assets/img/onboarding-wizard/widget.JPG" class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
          <div class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
            <h3 flex>{{('inline-widget')|translate|ucFirst}}</h3>
            <div>{{('webbooker_integration_code_inline_embed_select')|translate|ucFirst}}</div>
          </div>


          <div *ngIf="!hasGoogleApiKey" class="no-google-key" flex="35">
            <mat-icon>warning</mat-icon>{{'integration_no_google_key' | translate}}

            <button (click)="this.openGoogleKey()" class="push-top edit-button"
                    color="accent"
                    flex
                    mat-raised-button>
              {{'cloud_connect_googlekey_submit'|translate|ucFirst}}</button>
          </div>
        </div>
      </div>

      <div class="integration-type">
        <div (click)="openIntegrationInstructions('floating-popup-widget')" class="integration-type-text {{(type==='floating-popup-widget' ? 'activeOption' : '')}}"
             layout="row">
          <img src="../../../../../../assets/img/onboarding-wizard/iframe-removebg-preview.png" class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
          <div class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
            <h3 flex>{{('floating-popup-widget')|translate|ucFirst}}</h3>
            <div>{{('webbooker_integration_code_floating_widget_title')|translate|ucFirst}}</div>
          </div>

          <div *ngIf="!hasGoogleApiKey" class="no-google-key" flex="35">
            <mat-icon>warning</mat-icon>{{'integration_no_google_key' | translate}}
            <button (click)="this.openGoogleKey()" class="push-top edit-button"
                    color="accent"
                    flex
                    mat-raised-button>
              {{'cloud_connect_googlekey_submit'|translate|ucFirst}}</button>
          </div>
        </div>
      </div>

      <div class="integration-type">
        <div (click)="openIntegrationInstructions('popup-text')" class="integration-type-text {{(type==='popup-text' ? 'activeOption' : '')}}"
             layout="row">
          <img src="../../../../../../assets/img/onboarding-wizard/button.JPG" class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
          <div class="{{!hasGoogleApiKey ? 'disabled' : ''}}">
            <h3 flex>{{('popup-text')|translate|ucFirst}}</h3>
            <div>{{('webbooker_integration_code_popup_title')|translate|ucFirst}}</div>
          </div>

          <div *ngIf="!hasGoogleApiKey" class="no-google-key" flex="35">
            <mat-icon>warning</mat-icon>{{'integration_no_google_key' | translate}}
            <button (click)="this.openGoogleKey()" class="push-top edit-button"
                    color="accent"
                    flex
                    mat-raised-button>
              {{'cloud_connect_googlekey_submit'|translate|ucFirst}}</button>
          </div>
        </div>
      </div>

      <div (click)="openIntegrationInstructions('iframe')" class="integration-type {{(type==='iframe' ? 'activeOption' : '')}}"
           layout="row">
        <img src="../../../../../../assets/img/onboarding-wizard/iframe-removebg-preview.png">
        <div>
          <h3 flex>{{('iframe')|translate|ucFirst}}</h3>
          <div>{{('webbooker_integration_code_iframe_title')|translate|ucFirst}}</div>
        </div>
      </div>
    </mat-card-content>

    <a class="webbooker-ydalink yda-link-blue"
       href="https://intercom.help/yourdriverapp/en/articles/8937616-adding-the-web-booker-to-your-website" target="_new">{{'webbooker_integration_code_more_info' | translate}}</a>

    <mat-divider></mat-divider>
    <mat-card-actions class="dialog-buttons pull-bottom integration-select">
      <button (click)="activePane='right'" [disabled]="(type==='')" class="push-top push-right" color="accent" flex
              mat-raised-button>
        {{'continue'|translate|ucFirst}}</button>

      <a (click)="_dialogRef.close()" class="yda-link close-link">
        {{'close'|translate|ucFirst}}</a>
    </mat-card-actions>
  </div>

  <div class="integration-type-pane right-pane" select="[rightPane]">
    <mat-card-content *ngIf="type !== ''" class="integration-pane">
      <mat-card-title>{{'widget_integration_title'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle
        [innerHTML]="'webbooker_integration_code_subtitle'|translate|keepHtml"
        class="push-bottom"></mat-card-subtitle>

      <div class="integration-type-pane-right" flex="100" layout="row">
        <mat-accordion class="integration-settings-pane" flex="50">
          <h4>{{'webbooker_integration_settings'|translate|ucFirst}}</h4>
          <mat-expansion-panel [expanded]="true" class="section">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'booking_page_settings' | translate | ucFirst}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="section-content {{sections['booking']}}">
              <div *ngIf="hasSetting[type]['buttontext']">
                <mat-form-field>
                  <input [(ngModel)]="settings['buttontext']"
                         flex="100"
                         matInput
                         placeholder="{{'webbooker_integration_button_text'|translate|ucFirst}}"/>
                </mat-form-field>
              </div>

              <div *ngIf="hasSetting[type]['topbar']" class="settings-field-wrapper">
                <mat-checkbox [(ngModel)]="settings['topbar']">
                  {{'webbooker_integration_show_top_bar'|translate|ucFirst}}</mat-checkbox>
              </div>
              <div *ngIf="hasSetting[type]['logo']" class="settings-field-wrapper">
                <mat-checkbox [(ngModel)]="settings['logo']">
                  {{'webbooker_integration_show_logo'|translate|ucFirst}}</mat-checkbox>
              </div>
              <div *ngIf="hasSetting[type]['breadCrumbs']" class="settings-field-wrapper">
                <mat-checkbox [(ngModel)]="settings['breadCrumbs']">
                  {{'webbooker_integration_show_breadcrumbs'|translate|ucFirst}}</mat-checkbox>
              </div>

              <div *ngIf="hasSetting[type]['navigation']">
                <mat-form-field class="select-field" flex="100">
                  <mat-select [(ngModel)]="settings['navigation']"
                              placeholder="{{'webbooker_integration_navigation'|translate}}">
                    <mat-option *ngFor="let option of navigationOptions; let i = index" [value]="option">
                      {{'webbooker_integration_navigation_option_' + option | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="hasSetting[type]['language']">
                <mat-form-field class="select-field" flex="100">
                  <mat-select [(ngModel)]="settings['language']"
                              placeholder="{{'webbooker_integration_language'|translate}}">
                    <mat-option [value]="'auto_detect'">
                      {{'auto_detect' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let option of languageOptions; let i = index" [value]="option">
                      {{option | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="section">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{(type === 'iframe' ? 'booking_page_styles' : 'widget_settings') | translate | ucFirst}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="section-content">
              <div *ngFor="let option of hasSetting[type]['colors']; let i = index">
                <mat-form-field class="medium-input number-input select-field" flex flex="100">
                  <input [(colorPicker)]="settings[option]"
                         [(ngModel)]="settings[option]"
                         [cpColorMode]="'color'"
                         [cpDialogDisplay]="'popup'"
                         [cpDisableInput]="true"
                         [cpOKButton]="true"
                         [cpOutputFormat]="'hex'"
                         [cpPosition]="(i<3?'bottom':'top')+'-right'"
                         flex="100"
                         matInput
                         placeholder="{{'webbooker_integration_color_'+option|translate|ucFirst}}"/>

                  <div class="color-example" style="background-color:{{settings[option]}}"></div>
                </mat-form-field>
              </div>

              <div *ngIf="hasSetting[type]['buttonposition']" class="settings-field-wrapper">
                <mat-form-field class="select-field" flex="100">
                  <mat-select [(ngModel)]="settings['buttonposition']"
                              placeholder="{{'webbooker_integration_button_position'|translate}}">
                    <mat-option *ngFor="let option of buttonpositionOptions; let i = index" [value]="option">
                      {{'webbooker_integration_buttonposition_option_' + option.toLowerCase() | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="hasSetting[type]['buttonstyle']" class="settings-field-wrapper">
                <mat-form-field class="select-field" flex="100">
                  <mat-select [(ngModel)]="settings['buttonstyle']"
                              placeholder="{{'webbooker_integration_button_style'|translate}}">
                    <mat-option *ngFor="let option of buttonstyleOptions; let i = index" [value]="option">
                      {{'webbooker_integration_buttonstyle_option_' + option | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="hasSetting[type]['font']" class="settings-field-wrapper">
                <mat-form-field class="select-field" flex="100">
                  <mat-select [(ngModel)]="settings['font']"
                              placeholder="{{'webbooker_integration_iframe_style'|translate}}">
                    <mat-option *ngFor="let option of fontStyleOptions; let i = index" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="integration-code-pane table" flex="48">
          <h4>{{'webbooker_integration_code'|translate|ucFirst}}</h4>
          {{'webbooker_integration_code_inline_embed_title'|translate|ucFirst}}

          <div class="table-header push-bottom max-height" layout="row">
            <div *ngIf="type!=='iframe'" class="left-column share-link" id="inline-{{webbookerId}}"
                 layout="column">&#x3C;link
              rel=&#x22;stylesheet&#x22;
              href=&#x22;{{frontEndUrl}}/{{path}}/styles.css&#x22;&#x3E;

              &#x3C;script src=&#x22;{{frontEndUrl}}/{{path}}/webbooker.js&#x22;
              type=&#x22;module&#x22;&#x3E;&#x3C;/script&#x3E;

              &#x3C;{{tag}}
              formid=&#x27;{{webbookerId}}&#x27;
              {{(hasSetting[type]['navigation'] ? 'navigation=\'' + settings['navigation'] + '\'' : '')}}
              {{(!settings['topbar'] ? 'showTopbar=\'false\'' : '')}}
              {{(!settings['logo'] ? 'showLogo=\'false\'' : '')}}
              {{(!settings['breadCrumbs'] ? 'showbreadcrumbs=\'false\'' : '')}}
              {{(hasSetting[type]['colors'].includes('headercolor') ? 'headercolor=\'' + settings['headercolor'] + '\'' : '')}}
              {{(hasSetting[type]['colors'].includes('textcolor') ? 'textcolor=\'' + settings['textcolor'] + '\'' : '')}}
              {{(hasSetting[type]['colors'].includes('backgroundcolor') ? 'backgroundcolor=\'' + settings['backgroundcolor'] + '\'' : '')}}
              {{(hasSetting[type]['colors'].includes('buttontextcolor') ? 'buttontextcolor=\'' + settings['buttontextcolor'] + '\'' : '')}}
              {{(hasSetting[type]['colors'].includes('buttonbgcolor') ? 'textcolor=\'' + settings['buttonbgcolor'] + '\'' : '')}}
              {{(hasSetting[type]['buttonstyle'] ? 'buttonstyle=\'' + settings['buttonstyle'] + '\'' : '')}}
              {{(hasSetting[type]['buttonposition'] ? 'buttonposition=\'' + settings['buttonposition'] + '\'' : '')}}
              {{(hasSetting[type]['buttontext'] ? 'buttontext=\'' + settings['buttontext'] + '\'' : '')}}
              {{(type === 'popup-text' ? 'widgettype=\'' + type + '\'' : '')}}
              style=&#x27;padding:40px;&#x27;
              language=&#x27;{{settings['language']}}&#x27;&#x3E;&#x3C;/{{tag}}&#x3E;

              {{(type === 'popup-text' ?
                '&#x3C;button onClick=\'initMyOrder()\'&#x3E;Init my order &#x3C;/button&#x3E;' : '')}}
            </div>

            <div *ngIf="type==='iframe'" class="left-column">
              <div *ngIf="false" class="share-link push-bottom" id="iframe-{{webbookerId}}" layout="column">
                <a class='yda-link'
                   href="{{frontEndUrl}}/{{settings['language']}}/{{webbookerId}}/{{(hasSetting[type]['navigation']
                      ? settings['navigation'] : '')}}/{{(!settings['topbar'] ? 'false' : 'true')}}/{{(!settings['logo'] ? 'false' : 'true')}}/{{(!settings['breadCrumbs'] ? 'false' : 'true')}}/{{(hasSetting[type]['colors'].includes('headercolor') ?settings['headercolor'].replace('#','') : '')}}/{{(hasSetting[type]['colors'].includes('textcolor') ? settings['textcolor'].replace('#','') : '')}}/{{(hasSetting[type]['colors'].includes('backgroundcolor') ? settings['backgroundcolor'].replace('#','') : '')}}/"
                   target="_new"> {{frontEndUrl}}/{{settings['language']}}/{{webbookerId}}/
                  {{(hasSetting[type]['navigation'] ? settings['navigation'] : '')}}/{{(!settings['topbar'] ? 'false'
                    : 'true')}}/{{(!settings['logo'] ? 'false' : 'true')}}/{{(!settings['breadCrumbs'] ? 'false' :
                    'true')}}
                  /{{(hasSetting[type]['colors'].includes('headercolor') ? settings['headercolor'].replace('#', '') :
                    '')}}
                  /{{(hasSetting[type]['colors'].includes('textcolor') ? settings['textcolor'].replace('#', '') : '')}}
                  /{{(hasSetting[type]['colors'].includes('backgroundcolor') ? settings['backgroundcolor'].replace('#', '') : '')}}
                  /
                </a>
              </div>

              <div *ngIf="type==='iframe'" class="share-link push-bottom" id="iframe-{{webbookerId}}"
                   layout="column">&#x3C;iframe
                src=&#x22;{{frontEndUrl}}/{{settings['language']}}/{{webbookerId}}/{{(!settings['topbar'] ? 'false' : 'true')}}/{{(!settings['logo'] ? 'false' : 'true')}}/{{(!settings['breadCrumbs'] ? 'false' : 'true')}}/{{(hasSetting[type]['buttonstyle'] ? settings['buttonstyle'] : '')}}/{{(hasSetting[type]['colors'].includes('headercolor') ? settings['headercolor'].replace('#', '') :                  '')}}/{{(hasSetting[type]['colors'].includes('textcolor') ? settings['textcolor'].replace('#', '')                  : '')}}/{{(hasSetting[type]['colors'].includes('backgroundcolor') ? settings['backgroundcolor'].replace('#', '') : '')}}/{{(hasSetting[type]['font'] ? settings['font'] : '')}}&#x22; style=&#x22;padding:40px;width:100%;height:600px&#x22;&#x3E;&#x3C;/iframe&#x3E;</div>

              <a class="yda-link close-link" href="{{frontEndUrl}}/{{settings['language']}}/{{webbookerId}}/{{(!settings['topbar'] ? 'false' : 'true')}}/{{(!settings['logo'] ? 'false' : 'true')}}/{{(!settings['breadCrumbs'] ? 'false' : 'true')}}/{{(hasSetting[type]['buttonstyle'] ? settings['buttonstyle'] : '')}}/{{(hasSetting[type]['colors'].includes('headercolor') ? settings['headercolor'].replace('#', '') :
              '')}}/{{(hasSetting[type]['colors'].includes('textcolor') ? settings['textcolor'].replace('#', '')
              : '')}}/{{(hasSetting[type]['colors'].includes('backgroundcolor') ? settings['backgroundcolor'].replace('#', '') : '')}}/{{(hasSetting[type]['font'] ? settings['font'] : '')}}"
                 target="_new">
                {{'preview'|translate|ucFirst}}</a>
            </div>
          </div>

          <mat-divider></mat-divider>

          <mat-card-actions class="dialog-buttons pull-bottom">
            <button (click)="this.copyToClipboard((type==='iframe' ? 'iframe':'inline'))" class="push-top push-right" color="accent" flex
                    mat-raised-button>
              {{'copy_code'|translate|ucFirst}}</button>

            <a (click)="_dialogRef.close()" class="yda-link close-link">
              {{'close'|translate|ucFirst}}</a>

            <a (click)="openIntegrationInstructions('')"
               class="back-button yda-link close-link push-right-small back-button">
              <mat-icon>keyboard_arrow_left</mat-icon>
              {{'back'|translate|ucFirst}}
            </a>
          </mat-card-actions>
        </div>
      </div>
    </mat-card-content>
  </div>
</div>
