<!-- Search -->
<mat-form-field
  class="{{hasErrors ? 'error' : ''}}" style="width: 100%">
  <input #{{this.direction}} (blur)="addressChanged()"
         [defaultValue]="( this.address && this.address.internationalAlias ? this.address.internationalAlias : '')"
         [formControl]="autoComplete"
         [formGroup]="form"
         [matAutocomplete]="auto"
         autocomplete="{{this.direction}}-address"
         matInput
         placeholder="{{ this.placeholder | translate | ucFirst }}"
         type="text">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      (onSelectionChange)="getAddress(res)"
      *ngFor="let res of data"
      [value]="res.description">
      <span>
        <mat-icon>{{ getIcon(res.types[0]) }}</mat-icon>
        {{ res.name | ucFirst }}
      </span>
      <span *ngIf="res.description">{{ res.description }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div *ngIf="false" class="{{hasErrors ? 'active' : ''}}">
  <ng-container *ngFor="let error of errors.errors">
    <span *ngIf="error.text">{{error.text | translate | ucFirst}}</span>
  </ng-container>
</div>
