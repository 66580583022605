<div [formGroup]="form">
  <ng-container *ngFor="let input of getAutocompleteInputs()">
    <mat-form-field
      [floatLabel]="floatingLabel"
      [ngClass]="{ 'margin-top-minus-two-em': !allowEmpty }">
      <input (keyup)="keyup(input, $event)"
             (focus)="focusOption(input, $event)"
             [formControl]="input.autoComplete"
             [matAutocomplete]="auto"
             [matTooltipPosition]="input.matTooltipPosition"
             [matTooltipShowDelay]="input.matTooltipShowDelay"
             flex="33"
             matInput
             matTooltip="{{ input.tooltip | translate | ucFirst }}"
             placeholder="{{ input.placeholder | translate | ucFirst }}"
             #locationInput_{{input.type}}
             required="required"
             type="text">
      <mat-autocomplete tab-selected
                        #auto="matAutocomplete"
                        [displayWith]="displayResult"
                        autoActiveFirstOption>
        <mat-option
          *ngIf="allowEmpty && !query && (this.company && this.company.settings && this.company.settings.serviceArea)"
          [value]="{
              _id: this.company.settings.serviceAreaId,
              descriptor : '',
              name : ucFirst(_translationService.instant('servicearea')),
              type : 'area'
            }">
          <span><mat-icon>map</mat-icon></span>
          {{ 'servicearea' | translate | ucFirst }}
        </mat-option>
        <mat-option
          *ngIf="allowEmpty && !query && !(this.company && this.company.settings && this.company.settings.serviceArea)"
          [value]="{
            _id: null,
            descriptor : '',
            name : ucFirst(_translationService.instant('everywhere')),
            type : 'point'
          }">
          <span><mat-icon>accessibility_new</mat-icon></span>
          {{ 'everywhere' | translate | ucFirst }}
        </mat-option>
        <mat-option (onSelectionChange)="setLocation(input, $event.source.value)"
                    *ngFor="let location of input.searchResults"
                    [matTooltip]="location.name"
                    [value]="location"
                    matTooltipPosition="right">
          <mat-icon>{{ getIcon(location.descriptor) }}</mat-icon>
          {{ location.name | ucFirst }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>
</div>
