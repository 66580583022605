import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {UtilityService} from '../../../../services/utility.service';
import {Company} from '../../../../models/company';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {TdDataTableService} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Promotion} from '../../../../models/promotion';
import {ExporterService} from '../../../../services/exporter.service';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent extends CollectionComponent implements OnInit, OnDestroy {
  company: Company;
  loaderName = 'policy-list';
  modelName = 'exports';
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'description', label: 'description', maxSize: 'max-w-100p', active: true},
    {name: 'download', label: 'download', sortable: false, active: false}
  ];

  constructor(
    protected injector: Injector,
    public _loadingService: TdLoadingService,
    public _vault: CoolLocalStorage,
    public _dataTableService: TdDataTableService,
    private _activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    private _exportsService: ExporterService,
  ) {
    super(injector);
    this.modelService = _exportsService;

    const parentParams: any = this._activatedRoute.parent.snapshot.params;
    this.companyId = parentParams.id;
    this.companyId = this._route.parent.snapshot.paramMap.get('id');
    this.context = 'company';
    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    _translateService.get([
      `confirm`, 'passenger',
      'payment_origin_inTaxi', 'payment_origin_inApp', 'payment_origin_online', 'payment_origin_invoice'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  loadData = () => {
    const query = {
      'where': {
        'companyId': this.companyId
      },
      'order': 'created ASC'
    };

    this._exportsService.getAll(query).subscribe((exports: Promotion[]) => {
      // const self = this;
      this.collectionData = [];
      this.filteredData = [];

      exports.forEach((promotion, i) => {
        this.collectionData.push(promotion);
      });
      this._loadingService.resolve('exports.list');
    }, (error => {
      console.error(error);
      this._loadingService.resolve('exports.list');
    }));
  }
}
