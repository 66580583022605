<mat-list-item>
  <mat-icon mat-list-icon>payment</mat-icon>
  <h4 mat-line>{{'pricing'|translate|ucFirst}}</h4>
</mat-list-item>

<div [formGroup]="form" [mediaClasses]="['push-sm']" flex="100" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item flex-gt-sm="50">
    <app-ride-edit-product (pricingError)="priceCalculationPricingError($event)" (productChanged)="productChanged($event)" [context]="context" [form]="form" [mode]="mode"
                           [ride]="ride"
                           class="form-left"
                           flex-gt-sm="50"
                           layout="row"
                           layout-gt-md='none'
                           tdMediaToggle="gt-xs"></app-ride-edit-product>

    <mat-form-field class="form-right" flex-gt-sm="50">
      <mat-label>{{'pricing_type'|translate|ucFirst}}</mat-label>
      <mat-select (selectionChange)="this.priceMethodChanged();"
                  class="row-select"
                  flex="100"
                  formControlName="priceMethod" id="priceMethod" required>
        <mat-option *ngIf="translations['onmeter']"
                    value="onMeter">{{'metered'|translate|ucFirst}}</mat-option>
        <mat-option value="fixed">{{'fixed_price'|translate|ucFirst}}</mat-option>

        <mat-option *ngIf="false" value="hourlyMeter">{{'hourly_booking_metered'|translate|ucFirst}}</mat-option>
        <mat-option *ngIf="false" value="hourlyFixed">{{'hourly_booking_fixed'|translate|ucFirst}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>

  <mat-list-item class="mobile-row" flex-gt-sm="50">
    <mat-form-field *ngIf="priceMethod === 'onMeter' &&
    !this.form.controls['isHourlyBooking'].value" flex-gt-sm="100">
      <mat-label>{{'price'|translate|ucFirst}}</mat-label>
      <mat-select (selectionChange)="this.calcMethodChanged();"
                  class="row-select"
                  flex="100"
                  formControlName="calculationMethod" id="calculationMethod" required>
        <mat-option value="manual">{{'meter_extra'|translate|ucFirst}}</mat-option>
        <mat-option (click)="(groupPriceDisabled!=='' ? showNoPrice() : false)" [disabled]="(groupPriceDisabled!=='')"
                    value="group_pricing_rules">
          <span class="indication">
            {{'group_pricing_rules_extra'|translate|ucFirst}}
            <span *ngIf="priceMethod === 'onMeter'" class="indication {{groupPriceDisabled}}">
              <span *ngIf="priceEstimate.total > 0">({{'price_indication' | translate | ucFirst}}:</span>
              <span *ngIf="priceEstimate.total === 0 && showBaseRuleError">(
                {{'estimate_error'|translate}})</span>
              <span *ngIf="priceEstimate.total > 0"> {{this.currencySymbol}}
                {{(priceEstimate.total / 100).toFixed(2)}})</span>
            </span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="priceMethod === 'fixed' || priceMethod === 'hourlyFixed'" class="form-left" flex-gt-sm="50"
                    formGroupName="price">
      <span matPrefix>{{(this.currencyDisplay ? this.currencyDisplay : this.currencySymbol)}}&thinsp;</span>
      <mat-label>{{'price'|translate|ucFirst}}</mat-label>
      <input #jobPrice (blur)="valOrZero($event.target)"
             (change)="priceChanged()"
             (focus)="valOrZero($event.target); inputFocused($event)"
             [placeholder]="(this.totalFormat ? this.totalFormat : '0,00')"
             autocomplete="off"
             formControlName="total"
             matInput
             min="0"
             step='0.01' type="number">
    </mat-form-field>

    <mat-form-field *ngIf="priceMethod ===
      'onMeter' && this.form.controls['isHourlyBooking'].value" class="form-left" flex-gt-sm="50" formGroupName="priceMeta">
      <span matPrefix>{{(this.price.currencyDisplay ? this.price.currencyDisplay : this.currencySymbol)}}&thinsp;</span>
      <mat-label>{{'rate_per_hour'|translate|ucFirst}}</mat-label>
      <input #hourlyRate (blur)="valOrZero($event.target)"
             (change)="hourlyRateUpdated()"
             (focus)="valOrZero($event.target); inputFocused($event)"
             [placeholder]="(this.totalFormat ? this.totalFormat : '0,00')"
             autocomplete="off"
             formControlName="hourlyRate"
             matInput
             min="0"
             step='0.01'
             type="number">
    </mat-form-field>
  </mat-list-item>
</div>

<div [mediaClasses]="['push-sm']" class="push-top-margin" flex="100" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item flex-gt-sm="50">
    <app-ride-edit-paymentmethod (conditionalFormatting)="emitConditionalFormatting()" [company]="company"
                                 [form]="form"
                                 [paymentMeta]="paymentMeta"
                                 class="form-left-mobile"
                                 flex-gt-sm="50"></app-ride-edit-paymentmethod>

    <app-passenger-paymentmethods *ngIf="passenger && this.company && this.company.stripeAccountId"
                                  [company]="company" [formErrors]="formErrors" [form]="form" [passenger]="passenger"
                                  [paymentMethod]="form.controls['paymentMeta']['controls']['origin'].value"
                                  [ride]="ride"
                                  class="form-right" flex-gt-sm="50"></app-passenger-paymentmethods>
  </mat-list-item>
  <mat-list-item *ngIf="this.form.controls['paymentMeta']['controls']['origin'].value ===
  'creditcardOnline'" flex="100" flex-gt-sm="50">
    <app-edit-payment-moment *ngIf="(!this.ride ||
                      (this.ride && !this.ride.payment) ||
                      (this.ride && this.ride.payment && this.ride.price &&
                         (this.ride.openBalance !== 0 &&
                         this.ride.payment.status !== 'CAPTURED' ||
                         this.ride.price.total !== this.form.controls['price'].value.total)
                       )
                     )" [form]="form" [openbalance]="this.ride.openBalance" [ride]="this.ride"
                             flex="100">
    </app-edit-payment-moment>

    <div *ngIf="(this.ride && this.ride.payment && this.ride.price && this.ride.openBalance === 0 &&
    this.ride.payment.status === 'CAPTURED' && this.ride.price.total ===
    this.form.controls['price'].value.total)">
      {{'payment_status'|translate|ucFirst}}:
      <span class="{{this.paymentStatusColor}}">{{this.paymentStatus | translate | ucFirst}}</span>
    </div>
  </mat-list-item>
</div>
