import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DebtorService} from '../../../../../../../../services/debtor.service';
import {environment} from '../../../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import moment from 'moment/moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-condition-wizard-dialog',
  templateUrl: './add-condition-wizard-dialog.component.html',
  styleUrls: ['./add-condition-wizard-dialog.component.scss']
})
export class AddConditionWizardDialogComponent implements OnInit {
  @ViewChild('secondMatchValue') searchElement: ElementRef;

  form: any
  index = 0;
  _localRequirements = [];
  requirements = [];
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');

  matchValues = [
    'job_created',
    'job_changed_assigned',
    'job_changed_driving_to_pickup',
    'job_changed_arrived_at_pickup',
    'job_changed_started',
    'job_changed_completed',
    'job_changed_canceled',
    'driver_changed',
    'requesteddate_changed',
  ];
  matchValues2 = [
    {
      field: 'departure',
      contains: 'string',
    },
    {
      field: 'destination',
      contains: 'string',
    },
    // {
    //   field: 'requestedDate',
    //   contains: 'date_between',
    // },
    {
      field: 'paymentMethod',
      contains: 'paymentMethod',
    },
    {
      field: 'debtorCode',
      contains: 'debtor',
    },
    {
      field: 'note',
      contains: 'string',
    },
  ];
  matchValues2Input = [];
  matchValues3Minutes = [];

  matchValues3 = [
    {
      field: 'sent_immediately',
      contains: 'none',
    },
    {
      field: 'wait_for',
      contains: 'minutes',
    },
    {
      field: 'is_before_requested_date',
      contains: 'minutes',
    },
    {
      field: 'is_before_etp',
      contains: 'minutes',
    }
  ];
  hasAnd: boolean;
  paymentMethods = [];
  debtors = [];

  constructor(
    private _debtorService: DebtorService,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _translationService: TranslateService,
    private _vault: CoolLocalStorage,
    public dialogRef: MatDialogRef<AddConditionWizardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (environment.paymentMethods) {
      this.paymentMethods = environment.paymentMethods || [];
    }

    this.matchValues2Input = this.matchValues2.filter((m) => {
      return (m.contains === 'string')
    }).map((m) => {
      return m.field;
    });
    this.matchValues3Minutes = this.matchValues3.filter((m) => {
      return (m.contains === 'minutes')
    }).map((m) => {
      return m.field;
    });

    this.form = data.form;

    if (this.form.controls['requirements'].controls.length === 0) {
      this.form.controls['requirements'].controls.push(this._formBuilder.group({
        firstMatch: ['job_created', [Validators.required]],
        secondMatch: ['', []],
        secondMatchValue: ['', []],
        startDate: ['', []],
        endDate: ['', []],
        timeMatch: ['sent_immediately', [Validators.required]],
        timeValue: ['', []],
      }, {validators: []}));
      this.form.controls['requirements'].updateValueAndValidity();
    }

    this.requirements = data.requirements;
    if (data.index) {
      this.index = data.index;
    }

    this._debtorService.getAll({where: {ownerId: data.company.id, ownerType: 'Company'}}).subscribe((debtors) => {
      this.debtors = debtors;
    })
  }

  ngOnInit(): void {
    if (this.form.controls['requirements'].controls[this.index].controls.secondMatch.value !== '') {
      this.hasAnd = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.controls['requirements'].controls[this.index].status === 'INVALID') {
      console.log(this.form.controls['requirements'].controls[this.index]);
      this._snackBar.open(this._translationService.instant('requirements_not_valid_form'))
    } else if (this.validateInput()) {
      this.dialogRef.close();
    } else {
      console.log('check');
    }
  }

  validateInput() {
    if (this.matchValues3Minutes.includes(this.form.controls['requirements'].controls[this.index].controls['timeMatch'].value)) {
      if (this.form.controls['requirements'].controls[this.index].controls['timeValue'].value !== '') {
        return true;
      } else {
        this.form.controls['requirements'].controls[this.index].controls['timeValue'].markAsTouched();

        this.form.controls['requirements'].controls[this.index].controls['timeValue'].setErrors({'required': true});
        this.form.controls['requirements'].controls[this.index].controls['timeValue'].updateValueAndValidity();
        return false;
      }
    } else {
      return true;
    }
  }

  getMatchValues3() {
    return this.matchValues3.filter((o) => {
      if (['job_changed_started', 'job_changed_completed', 'job_changed_canceled'].includes(this.form.controls['requirements'].controls[this.index].controls.firstMatch.value)) {
        if (o.field === 'sent_immediately' || o.field === 'wait_for') {
          return o;
        }
      } else if (['job_changed_driving_to_pickup'].includes(this.form.controls['requirements'].controls[this.index].controls.firstMatch.value)) {
        return o;
      } else {
        if (o.field !== 'is_before_etp') {
          return o;
        }
      }
    });
  }

  timeChanged() {
    if (this.form.controls['requirements'].controls[this.index].controls.timeMatch.value === 'sent_immediately') {
      this.form.controls['requirements'].controls[this.index].controls.timeValue.setValue('');
      this.form.controls['requirements'].controls[this.index].controls.timeValue.setValidators([]);
    } else {

      console.log('setValidators');
      this.form.controls['requirements'].controls[this.index].controls.timeValue.setValidators([Validators.required]);
      this.form.controls['requirements'].controls[this.index].controls['timeValue'].updateValueAndValidity();
    }
  }

  secondMatchChanged() {
    if (this.form.controls['requirements'].controls[this.index].controls.secondMatch.value === 'debtorCode') {
      this.form.controls['requirements'].controls[this.index].controls['secondMatchValue'].setValue(this.debtors[0].companyName);
    }
    if (this.form.controls['requirements'].controls[this.index].controls.secondMatch.value === 'paymentMethod') {
      this.form.controls['requirements'].controls[this.index].controls['secondMatchValue'].setValue(this.paymentMethods[0]);
    }
  }

  addCondition(index) {
    this.form.controls['requirements'].controls[index].controls['secondMatch'].setValue('departure');
    this.hasAnd = true;

    this.form.controls['requirements'].controls[this.index].controls.secondMatch.setValidators([Validators.required]);
    this.form.controls['requirements'].controls[this.index].controls.secondMatchValue.setValidators([Validators.required]);

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  removeCondition(index) {
    this.hasAnd = false;

    this.form.controls['requirements'].controls[this.index].controls.secondMatch.setValidators([]);
    this.form.controls['requirements'].controls[this.index].controls.secondMatchValue.setValidators([]);
    this.form.controls['requirements'].controls[index].controls['secondMatch'].setValue('');
    this.form.controls['requirements'].controls[index].controls['secondMatchValue'].setValue('');
  }
}
