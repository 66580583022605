import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address} from '../../../../../../../models/address';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-ride-edit-location',
  templateUrl: './ride-edit-location.component.html',
  styleUrls: ['./ride-edit-location.component.scss']
})
export class RideEditLocationComponent implements OnInit {
  @Input() departure: Address;
  @Input() destination: Address;
  @Input() form: UntypedFormGroup;
  @Output() addessUpdated: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

  }
}
