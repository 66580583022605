<mat-list-item>
  <mat-icon mat-list-icon>place</mat-icon>
  <h4 mat-line>{{'location_and_time'|translate|ucFirst}}</h4>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-md" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value address-pane mobile-row" flex-gt-sm="50">
    <app-ride-edit-address [address]="departure"
                           [direction]="'departure'"
                           [form]="form"
                           [placeholder]="'departure'|translate|ucFirst" class="full-width"></app-ride-edit-address>
  </mat-list-item>
  <mat-list-item class="value mobile-row" flex-gt-sm="50">
    <app-ride-edit-address [address]="destination"
                           [direction]="'destination'"
                           [form]="form"
                           [placeholder]="'destination'|translate|ucFirst" class="full-width"></app-ride-edit-address>
  </mat-list-item>
</div>
