import {GeoPoint} from './geo-point';

export class Address {
  city?: string;
  streetName?: string;
  postalCode?: string;
  houseNumber?: string;
  synonym?: string;
  internationalAlias?: string;
  gps: GeoPoint;
  gpsSpeed?: number;
  addressLine?: number;
  gpsBearing?: number;
  gpsAccuracy?: number;
  gpsTime?: string;
  gpsOrigin?: string;
  gpsPriority?: string;
  placeId?: string;
  placesId?: string;
  description?: string;
  poiCode?: string;
  countryCode?: string;
  id?: number;
  type?: string;

  constructor() {
    this.gps = new GeoPoint();
  }
}
