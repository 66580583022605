import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DaAppInstallService} from '../../../services/da-app-install.service';
import {environment} from '../../../../environments/environment';
import {Company} from '../../../models/company';
import {TdDialogService} from '@covalent/core/dialogs';
import {PurchaseWebbookerComponent} from './parts/purchase-webbooker/purchase-webbooker.component';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../services/webbooker.service';
import {User} from '../../../models/user';
import {ContractService} from '../../../services/contract.service';
import {IntegrationService} from '../../../services/integration.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {Title} from '@angular/platform-browser';
import {ShareWebbookerComponent} from './parts/share-webbooker/share-webbooker.component';
import {WebbookerIntegrationCodeComponent} from './parts/webbooker-integration-code/webbooker-integration-code.component';
import md5 from 'crypto-js/md5';
import {GlobalFunctions} from '../../../functions/functions';
import * as tpsDaAppInstallService from '../../../services/tps/da-app-install.service';
import {DeleteWebbookerDialogComponent} from '../settings/apps/parts/apps-upsert/parts/webbooker-tab/parts/delete-webbooker-dialog/delete-webbooker-dialog.component';

@Component({
  selector: 'app-webbooker',
  templateUrl: './webbooker.component.html',
  styleUrls: ['./webbooker.component.scss'],
  providers: [DaAppInstallService, tpsDaAppInstallService.DaAppInstallService]
})
export class WebbookerComponent implements OnInit, OnDestroy {
  modelName = 'daappinstall';
  company: Company;
  user: User;
  context = 'company';
  translations = [];
  webbookers = [];
  integrationInstructions = false;
  removeEventListener: any;

  constructor(private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title,
              private _route: ActivatedRoute,
              private _vault: CoolLocalStorage,
              private _dialogService: TdDialogService,
              private _router: Router,
              private _daAppInstallService: DaAppInstallService,
              private _tpsDaAppInstallService: tpsDaAppInstallService.DaAppInstallService,
              private _contractService: ContractService,
              private _integrationService: IntegrationService,
              private _snackbar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _webbookerService: WebbookerService,
              private renderer: Renderer2,
              private elRef: ElementRef
  ) {
    const self = this;
    let company;
    if (this._route.parent && this._route.parent.snapshot && this._route.parent.snapshot.data && this._route.parent.snapshot.data.company) {
      company = this._route.parent.snapshot.data.company;
    } else {
      company = this._route.data;
    }
    this.company = company;

    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.user = user;

    _translateService.get([
      'webbooker_page_title',
      'webbooker_added_error',
      'webbooker_added_message',
      'webbooker_help_text_title',
      'webbooker_help_text'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this._titleService.setTitle(this._translateService.instant('webbooker_tabpage_title'));
      this._navigationService.setBodyTitle(this.translations[`webbooker_page_title`]);
      this._navigationService.setHelpTextTitle(this.translations[`webbooker_help_text_title`]);
      this._navigationService.setHelpText(this.translations[`webbooker_help_text`]);
    });

    setTimeout(function () {
      self.loadData();
    }, 100);
  }

  ngOnInit(): void {
  }

  shareWebbooker(webbookerId, url): void {
    const self = this;
    self._dialogService.open(ShareWebbookerComponent, {
      maxHeight: '80%',
      width: '520px',
      data: {
        parent: this,
        webbookerUrl: url,
        webbookerId: webbookerId
      },
    });
  }

  openWebbookerIntegrationCode(webbookerId, url, webbooker): void {
    const self = this;
    self._dialogService.open(WebbookerIntegrationCodeComponent, {
      maxHeight: '90%',
      height: 'auto',
      maxWidth: (window.innerWidth < 600 ? '100%' : '800px'),
      data: {
        parent: this,
        webbookerUrl: url,
        webbooker: webbooker,
        webbookerId: webbookerId
      },
    });
  }

  loadData(): void {
    const self = this;
    if (!self.company.id) {
      setTimeout(function () {
        self.loadData();
      }, 500);
      return;
    }

    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.company.id,
      }
    }).subscribe((bookers) => {
      self.webbookers = bookers.filter((b) => {
        return (b.formId)
      });

      self.webbookers = self.webbookers.map((booker) => {
        booker.name = booker.settings.name;
        if (booker.formId) {
          booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
          if (!booker.JWTtoken) {
            self._daAppInstallService.refreshToken(booker.formId)
              .then((token) => {
                booker.JWTtoken = token;
                self._vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
              });
          }
          self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
            .subscribe((formDetails) => {
              booker.form = formDetails;
              if (formDetails.config) {
                booker.url = `${environment.orderFormFrontEndUrl}/${self.user.locale.toLowerCase()}/${(formDetails.config.customUrl ? formDetails.config.customUrl : booker.formId)}`;

                if (formDetails.config.logo) {
                  booker.logoUrl = formDetails.config.logo;
                }
              }
            });

          GlobalFunctions.checkWebBookerForError(this._daAppInstallService, this._tpsDaAppInstallService, booker, this.company).then((errors) => {
            booker.priceError = errors;
          });
        }
        return booker;
      });
    });
  }

  navigate(id: string): void {
    this._router.navigate([`/groups/${this.company.id}/webbookers/${id}/details`]);
  }

  openPanel(item: any): void {
    const self = this;
    const hash = md5(`billyJeans_${new Date('Y-m-d')}_${(item.form.config.customUrl ? item.form.config.customUrl : item.form.id)}`);
    window.open(`${environment.orderFormFrontEndUrl}/support/${hash}/${self.user.locale.toLowerCase()}/${(item.form.config.customUrl ? item.form.config.customUrl : item.form.id)}`);
  }

  addNewWizard(): void {
    const self = this;
    if (self.webbookers.length > 0) {
      if (self.company.ActiveContract && self.company.ActiveContract.type === 'free-trial') {
        self._dialogService.open(PurchaseWebbookerComponent, {
          maxHeight: '80%',
          maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
          data: {
            parent: this,
            inTrial: true
          },
        });
      } else {
        self._dialogService.open(PurchaseWebbookerComponent, {
          maxHeight: '80%',
          maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
          data: {parent: this},
        });
      }
    } else {
      self._loadingService.register('newwebbooker');
      self._integrationService.installOrderForm(self.company.id)
        .subscribe(() => {
          self._snackbar.open(self.translations['webbooker_added_message'], '', {duration: 3000});
          self._loadingService.resolve('newwebbooker');
          self.loadData();
        });
    }
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.removeEventListener = self.renderer.listen(self.elRef.nativeElement, 'click', (event) => {
        if (event && event.target && event.target && event.target.attributes && event.target.attributes.link) {
          if (event.target instanceof HTMLAnchorElement) {
            // Prevent opening anchors the default way
            event.preventDefault();
            // Your custom anchor click event handler
            self.openLink(event);
          }
        }
      });
    }, 1000);
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    if (this.removeEventListener) {
      this.removeEventListener();
    }
  }

  openLink(event) {
    this._router.navigate([
      `/groups/${this.company.id}/${event.target.attributes.link.nodeValue}`
    ]);
  }


  openWebbookerDelete(booker) {
    this._dialogService.open(DeleteWebbookerDialogComponent, {
      maxHeight: '90%',
      minWidth: '300px',
      data: {
        openFromDialog: true,
        parent: this,
        webbooker: booker,
        daAppInstall: booker,
        company: this.company,
      },
    });
  }
}
